import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAxios from "../../utils/useAxios";
import Header from "../../components/Agent/Header";
import Sidebar from "../../components/Agent/Sidebar";
import BackToTop from "../../components/BackToTop";
import { encryptParam } from "../../utils/crypto";
import Spinner from "../../components/Spinner";

function ClasseManagement() {
  useEffect(() => {
    document.title = "Gestion Des Classes";
  }, []);

  const axiosInstance = useAxios();
  const [classes, setClasses] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [loading, setLoading] = useState(false);
  const [latestSchoolYear, setLatestSchoolYear] = useState(null);

  const fetchLatestSchoolYear = async () => {
    try {
      const response = await axiosInstance.get("/schoolyears/");
      const schoolYears = response.data;
      if (schoolYears.length > 0) {
        setLatestSchoolYear(schoolYears[schoolYears.length - 1].id);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    fetchLatestSchoolYear();
  }, []);

  const fetchClasses = async (level) => {
    if (!latestSchoolYear) return;

    setLoading(true);
    try {
      const response = await axiosInstance.get(`/classes-by-level/`, {
        params: { level, school_year_id: latestSchoolYear },
      });
      setClasses(response.data);
    } catch (error) {
      // console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchClasses(selectedLevel);
  }, [selectedLevel]);

  const handleLevelChange = (event) => {
    setSelectedLevel(event.target.value);
  };

  return (
    <div className="container-xxl position-relative bg-white d-flex p-0">
      <Sidebar />

      <div className="content">
        <Header />

        <div className="container-fluid pt-4 px-4">
          <div className="row my-2">
            <div className="col-sm-12 col-xl-6">
              <h3 className="text-uppercase">
                Gestion des classes
              </h3>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-sm-12 col-xl-6 mb-1">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Voir les classes par niveau
              </button>

              <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Veuillez selectionner un niveau
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="bg-light rounded h-100 p-4">
                        <select
                          id="classe"
                          name="classe_id"
                          value={selectedLevel}
                          onChange={handleLevelChange}
                          className="form-select mb-3"
                        >
                          <option value="">Choisir un niveau</option>
                          <option value="primaire">Primaire</option>
                          <option value="college">Collège</option>
                          <option value="lycee">Lycée</option>
                        </select>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-primary" data-bs-dismiss="modal">OK</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-xl-6 mb-1">
              <Link to="add-classe" className="btn btn-success text-uppercase">
                Ajouter Une Classe 
              </Link>
            </div>
          </div>

          <div className="row g-4">
            <div className="col-sm-12 col-md-12 col-xl-12">
              <div className="bg-light rounded h-100 p-4">
                <div className="table-responsive">
                  {loading ? (
                    <Spinner />
                  ) : classes.length > 0 ? (
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">N°</th>
                          <th scope="col">Titre</th>
                          <th scope="col">Niveau</th>
                          <th scope="col">Année Scolaire</th>
                          <th scope="col">Modifier</th>
                        </tr>
                      </thead>
                      <tbody>
                        {classes.map((classe, i) => (
                          <tr key={classe.id}>
                            <td>{i + 1}</td>
                            <td>{classe.name}</td>
                            <td className="text-capitalize">{classe.level}</td>
                            <td className="text-capitalize">
                              {classe.school_year.start_date} - {classe.school_year.end_date}
                            </td>
                            <td>
                              <Link
                                to={`/dashboard/classe/edit-classe/${encryptParam(classe.id)}`}
                                className="btn btn-sm btn-warning"
                              >
                                Modifier
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>Aucune classe disponible pour ce niveau.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BackToTop />
    </div>
  );
}

export default ClasseManagement;
