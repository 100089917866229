import useAxios from "../../utils/useAxios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../../components/Agent/Header";
import Sidebar from "../../components/Agent/Sidebar";

const swal = require("sweetalert2");

const AddStudent = () => {
  useEffect(() => {
    document.title = "Ajouter Un Élève";
  });

  const navigate = useNavigate();
  const axiosInstance = useAxios();
  const [classes, setClasses] = useState([]);
  const [schoolYear, setSchoolYear] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    matricule: "",
    date_of_birth: "",
    classe_id: "",
    school_year_id: ""
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const schoolYearResponse = await axiosInstance.get("/schoolyears/latest/");
        const latestSchoolYear = schoolYearResponse.data;
    
        // Mettez à jour l'état avec les données de l'année scolaire
        setSchoolYear(latestSchoolYear);
    
        // Utilisez directement l'ID de l'année scolaire dans la requête suivante
        setFormData((prevFormData) => ({
          ...prevFormData,
          school_year_id: latestSchoolYear.id,
        }));
    
        const classesResponse = await axiosInstance.get(`/classe/?school_year_id=${latestSchoolYear.id}`);
        setClasses(classesResponse.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const validateMatricule = (matricule) => {
    const regex = /^GN\d{7}$/;
    return regex.test(matricule);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!validateMatricule(formData.matricule)) {
      swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: "Le matricule doit commencer par 'GN' suivi de 7 chiffres.",
      });
      setIsLoading(false);
      return;
    }

    try {
      const response = await axiosInstance.post("/student/", formData);

      if (response.status === 201) {
        navigate("/dashboard/student");
        swal.fire({
          icon: "success",
          title: 'Succès',
          text: "Élève ajouté avec succès !",
        });
      } else {
        swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: "Veuillez saisir les informations valides",
        });
      }
    } catch (error) {
      swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: "Veuillez saisir les informations valides",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "school_year_id") {
      const fetchClasses = async () => {
        try {
          const response = await axiosInstance.get(`/classe/?school_year_id=${value}`);
          setClasses(response.data);
        } catch (error) {
          // console.log(error);
        }
      };

      fetchClasses();
    }
  };

  return (
    <div className="container-xxl position-relative bg-white d-flex p-0">
      <Sidebar />

      <div className="content">
        <Header />

        <div className="container-fluid pt-4 px-4">
          <div className="row g-4">
            <h3 className="mb-4 text-uppercase">Ajouter Un Élève</h3>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="bg-light rounded h-100 p-4">
              <label className="form-label">Année Scolaire</label>
              <select
                id="school_year_id"
                name="school_year_id"
                value={formData.school_year_id}
                onChange={handleChange}
                className="form-select mb-3"
                disabled
              >
                {schoolYear && (
                  <option key={schoolYear.id} value={schoolYear.id}>
                    {schoolYear.start_date} - {schoolYear.end_date}
                  </option>
                )}
              </select>
              <label className="form-label">Matricule</label>
              <input
                id="matricule"
                name="matricule"
                value={formData.matricule}
                onChange={handleChange}
                className="form-control mb-3"
                placeholder="Exemple : GN1234567"
              />
              <label className="form-label">Prénom</label>
              <input
                id="first_name"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                className="form-control mb-3"
                placeholder="Exemple : Jean Jacques"
              />
              <label className="form-label">Nom</label>
              <input
                id="last_name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                className="form-control mb-3"
                placeholder="Exemple : Soumaoro"
              />
              <label className="form-label">Date de naissance</label>
              <input
                id="date_of_birth"
                name="date_of_birth"
                value={formData.date_of_birth}
                onChange={handleChange}
                type="date"
                className="form-control mb-3"
              />
              <label className="form-label">Classe</label>
              <select
                id="classe_id"
                name="classe_id"
                value={formData.classe_id}
                onChange={handleChange}
                className="form-select mb-3"
              >
                <option value="" disabled>
                  Choisir une classe
                </option>
                {classes.map((classe) => (
                  <option key={classe.id} value={classe.id}>
                    {classe.name}
                  </option>
                ))}
              </select>

              <button
                type="submit"
                className="btn btn-primary m-2"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">Chargement...</span>
                  </div>
                ) : (
                  "Ajouter"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddStudent;
