import useAxios from "../../utils/useAxios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Header from "../../components/Parent/Header";
import Sidebar from "../../components/Parent/Sidebar";
import Spinner from "../../components/Spinner"; // Assurez-vous d'importer le composant Spinner
import config from "../../utils/config";

const swal = require("sweetalert2");

const ParentAddStudent = () => {
  useEffect(() => {
    document.title = "Ajouter Un Eleve";
  });

  // get token
  const token = localStorage.getItem("authTokens");
  const decoded = jwtDecode(token);
  const user_id = decoded.user_id;

  const navigate = useNavigate();
  const axios = useAxios();

  const [studentMatricule, setStudentMatricule] = useState("");
  const [studentData, setStudentData] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false); // État de chargement

  const validateMatricule = (matricule) => {
    const regex = /^GN\d{7}$/;
    return regex.test(matricule);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateMatricule(studentMatricule)) {
      swal.fire({
        icon: "error",
        title: "Erreur",
        text: "Le matricule doit commencer par 'GN' suivi de 7 chiffres (exemple : GN0011223)",
      });
      return;
    }
    setIsLoading(true); // Début du chargement
    try {
      const response = await axios.post("/get-student-by-matricule/", {
        matricule: studentMatricule,
      });
      setStudentData(response.data);
      setError("");
    } catch (error) {
      setStudentData(null);
      swal.fire({
        icon: "error",
        title: "Erreur",
        text: "Élève introuvable, verifiez le matricule",
      });
    } finally {
      setIsLoading(false); // Fin du chargement
    }
  };

  const handleAddStudent = async () => {
    setIsLoading(true); // Début du chargement
    console.log(studentData.id)
    console.log(user_id)
    try {
      const response = await axios.post(`/profile/${user_id}/profil-add-student/`, {
        student: studentData.id,
      });
      setError("");
      if (response.status === 200) {
        navigate("/dashboard/parent/student/");
        swal.fire({
          icon: "success",
          title: "Succès",
          text: "L'élève a été ajouté",
        });
      } else {
        swal.fire({
          icon: "error",
          title: "Erreur",
          text: "Elève déjà ajouté",
        });
      }
    } catch (error) {
      swal.fire({
        icon: "error",
        title: "Erreur",
        text: "Une erreur s'est produite lors de l'ajout de l'élève",
      });
    } finally {
      setIsLoading(false); // Fin du chargement
    }
  };

  return (
    <div className="container-xxl position-relative bg-white d-flex p-0">
      <Sidebar />

      <div className="content">
        <Header />

        <div className="container-fluid pt-4 px-4">
          <form onSubmit={handleSubmit} className="row g-4">
            <div className="col">
              <input
                type="text"
                value={studentMatricule}
                onChange={(e) => setStudentMatricule(e.target.value)}
                placeholder="Entrez le matricule de l'élève"
                className="form-control"
              />
            </div>
            <div className="col">
              <button type="submit" className="btn btn-primary" disabled={isLoading}>
                {isLoading ? <Spinner /> : "Rechercher"}
              </button>
            </div>
          </form>

          {error && (
            <div className="fs-3 alert alert-danger my-3" role="alert">
              {error}
            </div>
          )}

          {isLoading ? (
            <div className="text-center my-4">
              <Spinner />
            </div>
          ) : (
            studentData && (
              <div className="row g-4 my-2">
                <div className="col-sm-12 col-xl-6">
                  <div className="bg-light rounded h-100 p-4">
                    <div className="testimonial-item text-center">
                      <img
                        src={`${config.baseUrl}${studentData.image}`}
                        alt="Photos de l'eleve"
                        className="img-fluid rounded-circle mx-auto mb-4"
                        style={{ width: '150px', height: '150px' }}
                      />
                      <h5 className="mb-1">{studentData.first_name} {studentData.last_name}</h5>
                      <p>Matricule : {studentData.matricule}</p>
                      <p>Ecole : {studentData.school_infos.name}</p>
                      <p>Classe : {studentData.classe_infos.name}</p>
                      <p>Date de naissance : {studentData.date_of_birth}</p>
                      <button onClick={handleAddStudent} className="btn btn-success text-uppercase" disabled={isLoading}>
                        {isLoading ? <Spinner /> : "Ajouter l'élève"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default ParentAddStudent;
