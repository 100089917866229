import useAxios from "../../utils/useAxios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Agent/Header";
import Sidebar from "../../components/Agent/Sidebar";
import BackToTop from "../../components/BackToTop";

const swal = require("sweetalert2");

const AddClasse = () => {
  useEffect(() => {
    document.title = "Ajouter Une Classe";
  }, []);

  const navigate = useNavigate();
  const axiosInstance = useAxios();
  const [school, setSchool] = useState([]);
  const [lastSchoolYear, setLastSchoolYear] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    level: "",
    school: "",
    schoolYear: "",
  });

  const classesByLevel = {
    primaire: [
      "1ere Annee A", "1ere Annee B", "1ere Annee C", 
      "2eme Annee A", "2eme Annee B", "2eme Annee C", 
      "3eme Annee A", "3eme Annee B", "3eme Annee C",  
      "4eme Annee A", "4eme Annee B", "4eme Annee C",  
      "5eme Annee A", "5eme Annee B", "5eme Annee C",  
      "6eme Annee A", "6eme Annee B", "6eme Annee C",  
    ],
    college: [
      "7eme Annee A", "7eme Annee B", "7eme Annee C", 
      "8eme Annee A", "8eme Annee B", "8eme Annee C", 
      "9eme Annee A", "9eme Annee B", "9eme Annee C", 
      "10eme Annee A", "10eme Annee B", "10eme Annee C", 
    ],
    lycee: [
      "11eme S Litteraire 1", "11eme S Litteraire 2", "11eme S Litteraire 3", 
      "11eme S Scientifique 1", "11eme S Scientifique 2", "11eme S Scientifique 3", 
      "12eme S Litteraire 1", "12eme S Litteraire 2", "12eme S Litteraire 3", 
      "12eme S Scientifique 1", "12eme S Scientifique 2", "12eme S Scientifique 3", 
      "T SS 1", "T SS 2", "T SS 3", 
      "T SE 1", "T SE 2", "T SE 3", 
      "T SM 1", "T SM 2", "T SM 3", 
    ],
  };

  const [availableClasses, setAvailableClasses] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseSchool = await axiosInstance.get("/school/");
        setSchool(responseSchool.data);

        const responseSchoolYear = await axiosInstance.get("/schoolyears/latest/");
        const latestYear = responseSchoolYear.data;
        setLastSchoolYear(latestYear);
        setFormData((prevFormData) => ({
          ...prevFormData,
          schoolYear: latestYear.id
        }));
      } catch (error) {
        // console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleLevelChange = (e) => {
    const level = e.target.value;
    setFormData({ ...formData, level, name: "" }); 
    setAvailableClasses(classesByLevel[level] || []);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); 
    try {
      const response = await axiosInstance.post("/classe/", {
        ...formData,
        school_year: formData.schoolYear
      });
      if (response.status === 201) {
        navigate("/dashboard/classe");
        swal.fire({
          icon: "success",
          title: "Succès",
          text: "Classe ajoutée avec succès !"
        });
      } else {
        swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: "Veuillez saisir toutes les informations",
        });
      }
    } catch (error) {
      swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: "Veuillez saisir toutes les informations",
      });
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <div className="container-xxl position-relative bg-white d-flex p-0">
      <Sidebar />
      <div className="content">
        <Header />
        <div className="container-fluid pt-4 px-4">
          <div className="row g-4">
            <h3 className="mb-4 text-uppercase">Ajouter Une Classe</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="bg-light rounded h-100 p-4">
              <label className="form-label">Année Scolaire</label>
              <select
                id="schoolYear"
                name="schoolYear"
                value={formData.schoolYear}
                onChange={(e) =>
                  setFormData({ ...formData, schoolYear: e.target.value })
                }
                className="form-select mb-3"
                required
                disabled
              >
                {lastSchoolYear && (
                  <option key={lastSchoolYear.id} value={lastSchoolYear.id}>
                    {lastSchoolYear.start_date} - {lastSchoolYear.end_date}
                  </option>
                )}
              </select>

              <label className="form-label">Niveau</label>
              <select
                id="level"
                name="level"
                value={formData.level}
                onChange={handleLevelChange}
                className="form-select mb-3"
              >
                <option value="">Choisir un niveau</option>
                <option value="primaire">Primaire</option>
                <option value="college">Collège</option>
                <option value="lycee">Lycée</option>
              </select>

              <label className="form-label">Classe</label>
              <select
                id="name"
                name="name"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                className="form-select mb-3"
                disabled={!formData.level} 
                required
              >
                <option value="" disabled>
                  Choisir une classe
                </option>
                {availableClasses.map((classe, index) => (
                  <option key={index} value={classe}>
                    {classe}
                  </option>
                ))}
              </select>

              <label className="form-label">École</label>
              <select
                id="school"
                name="school"
                value={formData.school}
                onChange={(e) =>
                  setFormData({ ...formData, school: e.target.value })
                }
                className="form-select mb-3"
                required
              >
                <option value="" disabled>
                  Choisir une école
                </option>
                {school.map((school) => (
                  <option key={school.id} value={school.id}>
                    {school.name}
                  </option>
                ))}
              </select>

              <button
                type="submit"
                className="btn btn-primary m-2"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">Chargement...</span>
                  </div>
                ) : (
                  "Ajouter"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      <BackToTop />
    </div>
  );
};

export default AddClasse;
