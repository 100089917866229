import React from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../components/Parent/Sidebar";
import Header from "../../components/Parent/Header";

const BestStudentStatisticsDetails = () => {
    const location = useLocation();
    const { stat, monthName, year } = location.state || {};

    return (
        <div className="container-xxl position-relative bg-white d-flex p-0">
            <Sidebar />
            <div className="content">
                <Header />
                <div className="container-fluid pt-4 px-4">
                    <div className="bg-light rounded h-100 p-4">
                        <h3>Statistiques pour {stat?.student.first_name} {stat?.student.last_name}</h3>
                        <p>Classe: {stat?.student.classe_infos?.name}</p>
                        <p>Statistiques pour {monthName} {year}</p>
                        <p>Participation Active: {stat?.participation_active}</p>
                        <p>Bonne Conduite: {stat?.bonne_conduite}</p>
                        <p>Presence: {stat?.presence}</p>
                        <p>Bonne Moyenne: {stat?.bonne_moyenne}</p>
                        {/* Ajoutez d'autres statistiques ici */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BestStudentStatisticsDetails;
