import React from 'react';
import { Link } from "react-router-dom";

const Agent = () => {
  return (
    <div className="container-xxl py-5">
        <div className="container">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h6 className="text-secondary text-uppercase">Nos Meilleurs Agents</h6>
                <h1 className="mb-5">Nos Agents De Suivi Efficace</h1>
            </div>
            <div className="row g-4">
                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="team-item">
                        <div className="position-relative overflow-hidden">
                            <img className="img-fluid" src="img/team-1.jpg" alt="" />
                        </div>
                        <div className="team-text">
                            <div className="bg-light">
                                <h5 className="fw-bold mb-0">Jeanne Tolno</h5>
                                <small>Sainte Marie</small>
                            </div>
                            <div className="bg-primary">
                                <Link className="btn btn-square mx-1" to="/"><i className="fab fa-facebook-f"></i></Link>
                                <Link className="btn btn-square mx-1" to="/"><i className="fab fa-twitter"></i></Link>
                                <Link className="btn btn-square mx-1" to="/"><i className="fab fa-instagram"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="team-item">
                        <div className="position-relative overflow-hidden"> 
                            <img className="img-fluid" src="img/team-2.jpg" alt="" />
                        </div>
                        <div className="team-text">
                            <div className="bg-light">
                                <h5 className="fw-bold mb-0">Ibrahima Sow</h5>
                                <small>Ecole Française</small>
                            </div>
                            <div className="bg-primary">
                                <Link className="btn btn-square mx-1" to="/"><i className="fab fa-facebook-f"></i></Link>
                                <Link className="btn btn-square mx-1" to="/"><i className="fab fa-twitter"></i></Link>
                                <Link className="btn btn-square mx-1" to="/"><i className="fab fa-instagram"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="team-item">
                        <div className="position-relative overflow-hidden">
                            <img className="img-fluid" src="img/team-1.jpg" alt="" />
                        </div>
                        <div className="team-text">
                            <div className="bg-light">
                                <h5 className="fw-bold mb-0">Oumou Diallo</h5>
                                <small>Gs Excellence</small>
                            </div>
                            <div className="bg-primary">
                                <Link className="btn btn-square mx-1" to="/"><i className="fab fa-facebook-f"></i></Link>
                                <Link className="btn btn-square mx-1" to="/"><i className="fab fa-twitter"></i></Link>
                                <Link className="btn btn-square mx-1" to="/"><i className="fab fa-instagram"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="team-item">
                        <div className="position-relative overflow-hidden">
                            <img className="img-fluid" src="img/team-2.jpg" alt="" />
                        </div>
                        <div className="team-text">
                            <div className="bg-light">
                                <h5 className="fw-bold mb-0">Mory Camara</h5>
                                <small>Hamas</small>
                            </div>
                            <div className="bg-primary">
                                <Link className="btn btn-square mx-1" to="/"><i className="fab fa-facebook-f"></i></Link>
                                <Link className="btn btn-square mx-1" to="/"><i className="fab fa-twitter"></i></Link>
                                <Link className="btn btn-square mx-1" to="/"><i className="fab fa-instagram"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Agent;
