import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useAxios from "../../utils/useAxios";
import { jwtDecode } from "jwt-decode";
import Header from "../../components/Parent/Header";
import Sidebar from "../../components/Parent/Sidebar";
import { encryptParam } from "../../utils/crypto";
import Spinner from "../../components/Spinner";
import config from "../../utils/config";


function ParentNotificationManagement() {
  useEffect(() => {
    document.title = "Notifications des Élèves";
  }, []);

  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true); // Added loading state
  const axios = useAxios();

  const token = localStorage.getItem("authTokens");
  let user_id;
  if (token) {
    const decoded = jwtDecode(token);
    user_id = decoded.user_id;
  }

  useEffect(() => {
    if (user_id) {
      setLoading(true); // Set loading to true when fetching data
      try {
        axios.get(`/profile/${user_id}/student-list/`).then((res) => {
          setStudents(res.data[0]?.students || []);
          setLoading(false); // Set loading to false after fetching data
        });
      } catch (error) {
        // console.log(error);
        setLoading(false); // Set loading to false in case of error
      }
    }
  }, [user_id]);

  return (
    <div className="container-xxl position-relative bg-white d-flex p-0">
      <Sidebar />
      <div className="content">
        <Header />

        <div className="container-fluid pt-4 px-4">
          <div className="row g-4">
            <div className="col-sm-12 col-md-6 col-xl-6">
              <div className="h-100 bg-light rounded p-4">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <h4 className="mb-1 text-capitalize">Notifications</h4>
                </div>
                {loading ? (
                  <Spinner /> // Show spinner while loading
                ) : students.length > 0 ? (
                  students.map((student) => (
                    <Link
                      key={student.id}
                      to={`/dashboard/parent/notification/student/${encryptParam(student.id)}`}
                      className="d-flex align-items-center border-bottom py-3"
                    >
                      <img
                        className="rounded-circle flex-shrink-0"
                        src={config.baseUrl + student.image || `${config.baseUrl}/media/default.jpg`}
                        alt=""
                        style={{ width: "40px", height: "40px" }}
                      />
                      <div className="w-100 ms-3">
                        <div className="d-flex w-100 justify-content-between">
                          <h6 className="mb-0">{student.first_name + " " + student.last_name}</h6>
                        </div>
                        <span>Cliquez pour voir les notifications</span>
                      </div>
                    </Link>
                  ))
                ) : (
                  <div>
                    <p>Aucun élève disponible.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ParentNotificationManagement;
