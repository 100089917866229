import React, { useState, useEffect } from "react";
import useAxios from "../../utils/useAxios";
import {jwtDecode} from "jwt-decode";
import { useNavigate } from "react-router-dom";

import Header from "../../components/Agent/Header";
import Sidebar from "../../components/Agent/Sidebar";
import BackToTop from "../../components/BackToTop";
import { StudentsList } from "../../components/StudentsList";
import { ClassSelectionModal } from "../../components/ClassSelectionModal";
import { VerificationModal } from "../../components/VerificationModal";

const swal = require("sweetalert2");

function SendNotification() {
  useEffect(() => {
    document.title = "Envoi Notifications";
  });

  const navigate = useNavigate();
  const axios = useAxios();
  const token = localStorage.getItem("authTokens");
  const decoded = jwtDecode(token);
  const user_id = decoded.user_id;

  const [categoryNotifications, setCategoryNotifications] = useState([]);
  const [students, setStudents] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState(null);
  const [selectedNotifications, setSelectedNotifications] = useState({});
  const [teacherInfo, setTeacherInfo] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [latestSchoolYear, setLatestSchoolYear] = useState("");
  const [changedStudents, setChangedStudents] = useState([]);

  const defaultCategoryIds = [1, 4, 8];

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get("/category-notifications/");
        setCategoryNotifications(response.data);
      } catch (error) {
        // console.log(error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchSchoolYearAndClasses = async () => {
      try {
        const schoolYearResponse = await axios.get("/schoolyears/");
        const schoolYears = schoolYearResponse.data;
        if (schoolYears.length > 0) {
          const latestYear = schoolYears[schoolYears.length - 1];
          setLatestSchoolYear(latestYear.id);

          const classResponse = await axios.get(`/classe/?school_year_id=${latestYear.id}`);
          setClasses(classResponse.data);
        }
      } catch (error) {
        // console.log(error);
      }
    };

    fetchSchoolYearAndClasses();
  }, []);

  const handleClassChange = (e) => {
    setSelectedClassId(e.target.value);
  };

  useEffect(() => {
    const fetchStudentsByClass = async () => {
      if (selectedClassId) {
        try {
          const response = await axios.get(`/students-by-class/${selectedClassId}/`);
          const studentsData = response.data.students;
          const teacherData = response.data.classInfo.teacher;

          setStudents(studentsData);
          setTeacherInfo(teacherData);

          const initialNotifications = studentsData.reduce((acc, student) => {
            acc[student.id] = categoryNotifications.reduce((catAcc, category) => {
              catAcc[category.id] = defaultCategoryIds.includes(category.id);
              return catAcc;
            }, {});
            return acc;
          }, {});

          setSelectedNotifications(initialNotifications);
        } catch (error) {
          // console.log(error);
        }
      }
    };

    fetchStudentsByClass();
  }, [selectedClassId]);

  const handleNotificationChange = (studentId, categoryId) => {
    setSelectedNotifications((prevState) => {
      const studentNotifications = { ...prevState[studentId] };

      if (categoryId === 2 || categoryId === 3) {
        const isSelected = !studentNotifications[categoryId];
        studentNotifications[categoryId] = isSelected;
        studentNotifications[6] = isSelected;
        studentNotifications[10] = isSelected;

        Object.keys(studentNotifications).forEach((id) => {
          if (![categoryId, 6, 10, 11, 12].includes(Number(id))) {
            studentNotifications[id] = false;
          }
        });

        if (isSelected) {
          studentNotifications[categoryId === 2 ? 3 : 2] = false;
        }
      } else if (categoryId === 11 || categoryId === 12) {
        const isSelected = !studentNotifications[categoryId];
        studentNotifications[categoryId] = isSelected;

        if (isSelected) {
          studentNotifications[categoryId === 11 ? 12 : 11] = false;
        }
      } else if (!studentNotifications[2] && !studentNotifications[3]) {
        const group1 = [4, 5, 6];
        const group2 = [7, 8, 9, 10];
        const group3 = [11, 12];

        if (group1.includes(categoryId)) {
          group1.forEach((id) => {
            studentNotifications[id] = id === categoryId;
          });
        } else if (group2.includes(categoryId)) {
          group2.forEach((id) => {
            studentNotifications[id] = id === categoryId;
          });
        } else if (group3.includes(categoryId)) {
          group3.forEach((id) => {
            studentNotifications[id] = id === categoryId;
          });
        } else {
          studentNotifications[categoryId] = !studentNotifications[categoryId];
        }
      }

      return {
        ...prevState,
        [studentId]: studentNotifications,
      };
    });
  };

  const verifyChanges = () => {
    const changed = [];

    Object.keys(selectedNotifications).forEach((studentId) => {
      const categories = [];
      Object.keys(selectedNotifications[studentId]).forEach((categoryId) => {
        if (
          selectedNotifications[studentId][categoryId] &&
          !defaultCategoryIds.includes(parseInt(categoryId, 10))
        ) {
          categories.push(parseInt(categoryId, 10));
        }
      });

      if (categories.length > 0) {
        const student = students.find((s) => s.id.toString() === studentId);
        changed.push({ student, categories });
      }
    });

    setChangedStudents(changed);
  };

  const sendNotifications = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const notifications = [];

    let allStudentsValid = true;

    Object.keys(selectedNotifications).forEach((studentId) => {
      const categories = [];
      Object.keys(selectedNotifications[studentId]).forEach((categoryId) => {
        if (selectedNotifications[studentId][categoryId]) {
          categories.push(parseInt(categoryId, 10));
        }
      });

      if (categories.length < 3) {
        allStudentsValid = false;
      }

      if (categories.length >= 3 && teacherInfo) {
        const message = `Pour plus d'informations veuillez contacter M. ${teacherInfo.last_name} sur ce numéro : ${teacherInfo.phone}`;

        const notification = {
          user: user_id,
          sender: user_id,
          student: studentId,
          categories: categories,
          message: message,
          is_read: false,
          school_year_id: latestSchoolYear,
        };

        if (selectedDate) {
          notification.date = selectedDate;
        }

        notifications.push(notification);
      }
    });

    if (!allStudentsValid) {
      swal.fire({
        title: "Erreur",
        text: "Chaque élève doit avoir au moins 3 catégories sélectionnées.",
        icon: "error",
      });
      setIsLoading(false);
      return;
    }

    try {
      for (const notification of notifications) {
        const response = await axios.post("/agents/send-notifications/", notification);
        if (response.status !== 201) {
          throw new Error(`Error: ${response.status}`);
        }
      }
      navigate("/dashboard/notification");
      swal.fire({
        icon: 'success',
        title: 'Succès',
        text: 'Notifications envoyées avec succès',
      });
    } catch (error) {
      swal.fire({
        title: "Echec de l'envoi des notifications",
        icon: "error",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container-xxl position-relative bg-white d-flex p-0">
      <Sidebar />

      <div className="content">
        <Header />

        <div className="container-fluid pt-4 px-4">

          <div className="my-1">
            <div className="col-sm-12 col-xl-6">
              <h3 className="text-uppercase">Envoyer une notification</h3>
            </div>

              <button
                type="button"
                className="btn btn-primary my-3"
                data-bs-toggle="modal"
                data-bs-target="#classModal"
              >
                Selectionner la classe
              </button>

              <ClassSelectionModal
                selectedClassId={selectedClassId}
                handleClassChange={handleClassChange}
                classes={classes}
              />
          </div>
          <div className="col-sm-12 col-xl-6 mb-2">
              <form>
                <label className="text-danger fw-bold">
                  Date à modifier uniquement si la notification est passée
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="inputDate"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                />
              </form>
          </div>
            
          <form onSubmit={sendNotifications}>
            <div className="bg-light rounded h-100 p-2">
              <StudentsList
                students={students}
                categoryNotifications={categoryNotifications}
                selectedNotifications={selectedNotifications}
                handleNotificationChange={handleNotificationChange}
              />

              {students.length > 0 && (
                <div className="row">
                  <div className="col-sm-12 col-xl-6 my-2">
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-bs-toggle="modal"
                      data-bs-target="#verificationModal"
                      onClick={verifyChanges}
                    >
                      Verifier les informations
                    </button>

                    <VerificationModal
                      changedStudents={changedStudents}
                      categoryNotifications={categoryNotifications}
                    />
                  </div>
                  <div className="col-sm-12 col-xl-6 my-2">
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <div className="spinner-border spinner-border-sm" role="status">
                          <span className="sr-only">Chargement...</span>
                        </div>
                      ) : (
                        "Envoyer les notifications"
                      )}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      <BackToTop />
    </div>
  );
}

export default SendNotification;
