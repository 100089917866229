import { Link } from "react-router-dom";

function Topbar() {
  return (
    <div className="container-fluid bg-light d-none d-lg-block">
        <div className="row align-items-center top-bar">
            <div className="col-lg-3 col-md-12 text-center text-lg-start">
                <Link to="/" className="navbar-brand m-0 p-0">
                    <h1 className="text-primary m-0">School Liaison Guinée</h1>
                </Link>
            </div>
            <div className="col-lg-9 col-md-12 text-end">
                <div className="h-100 d-inline-flex align-items-center me-4">
                    <i className="fa fa-map-marker-alt text-primary me-2"></i>
                    <p className="m-0">Lambangny, Ratoma</p>
                </div>
                <div className="h-100 d-inline-flex align-items-center me-4">
                    <i className="far fa-envelope-open text-primary me-2"></i>
                    <p className="m-0">shs@safehavensolving.com</p>
                </div>
                <div className="h-100 d-inline-flex align-items-center">
                    <Link className="btn btn-sm-square bg-white text-primary me-1" to="/"><i className="fab fa-facebook-f"></i></Link>
                    <Link className="btn btn-sm-square bg-white text-primary me-1" to="/"><i className="fab fa-twitter"></i></Link>
                    <Link className="btn btn-sm-square bg-white text-primary me-1" to="/"><i className="fab fa-linkedin-in"></i></Link>
                    <Link className="btn btn-sm-square bg-white text-primary me-0" to="/"><i className="fab fa-instagram"></i></Link>
                </div>
            </div>
        </div>
    </div>
);
}

export default Topbar;