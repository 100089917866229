import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import Swal from "sweetalert2";
import useAxios from "../../utils/useAxios";
import Header from "../../components/Parent/Header";
import Sidebar from "../../components/Parent/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Compressor from 'compressorjs';
import Spinner from "../../components/Spinner";
import { decryptParam } from "../../utils/crypto";

function ParentStudentDetail() {
  useEffect(() => {
    document.title = "Détails Elève";
  }, []);

  const { id } = useParams();

  const token = localStorage.getItem("authTokens");
  const axiosInstance = useAxios();
  const [student, setStudent] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const [loadingImage, setLoadingImage] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [numMonths, setNumMonths] = useState(0); // Initializing to 0
  const [additionalMonths, setAdditionalMonths] = useState(0); // For additional months selection
  const [loading, setLoading] = useState(true);
  const [subscriptionInfo, setSubscriptionInfo] = useState({});
  const [firstNotificationMonth, setFirstNotificationMonth] = useState(null); // Initializing to null

  const navigate = useNavigate();

  const decoded = jwtDecode(token);
  const profile_id = decoded.user_id;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const idDecrypt = decryptParam(id);
      try {
        const response = await axiosInstance.get(`/student/${idDecrypt}/`);
        const studentData = response.data;
        setStudent(studentData);
        setImageUrl(studentData.image);
        fetchSubscriptionInfo(idDecrypt);
        fetchPaymentStatus();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  const fetchPaymentStatus = async () => {
    const idDecrypt = decryptParam(id);
    try {
        await axiosInstance.get(`/mtn/get_last_mobile_money_payment/${idDecrypt}/`);
    } catch (error) {
        // console.error('Erreur lors de la vérification du statut de paiement:', error);
    }
  };

  const fetchSubscriptionInfo = async (student_id) => {
    try {
      const response = await axiosInstance.get(`/unpaid-months/${student_id}/`);
      setSubscriptionInfo(response.data);
      setNumMonths(response.data.unpaid_months); // Set initial unpaid months
      setFirstNotificationMonth(response.data.first_notification_date);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemoveStudent = (student_id) => {
    Swal.fire({
      title: 'Êtes-vous sûr ?',
      text: "Vous ne pourrez pas revenir en arrière !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, retirer !'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axiosInstance.post(`/profile/${profile_id}/remove-student/${student_id}/`);
          if (response.status === 200) {
            Swal.fire({
              icon: 'success',
              title: 'Succès',
              text: "L'élève a été retiré",
            });
            navigate('/dashboard/parent/student');
          }
        } catch (error) {
          console.error(error);
          Swal.fire({
            icon: 'error',
            title: 'Erreur',
            text: "Une erreur s'est produite, veuillez réessayer",
          });
        }
      }
    });
  };

  const handleActivateStudent = (student) => {
    setSelectedStudent(student);
    setShowModal(true);
  };

  const handleAdditionalMonthsChange = (e) => {
    setAdditionalMonths(parseInt(e.target.value, 10));
  };

  const handleProceedToPayment = () => {
    setShowModal(false);
    const totalMonths = numMonths + additionalMonths;
    navigate('/dashboard/payment', { state: { student: selectedStudent, numMonths: totalMonths } });
  };

  const handleEditPhoto = async (student_id, file) => {
    setLoadingImage(true);
    new Compressor(file, {
      quality: 0.3,
      maxWidth: 800,
      maxHeight: 800,
      success: async (compressedResult) => {
        const newFile = new File([compressedResult], file.name, {
          type: compressedResult.type,
        });

        const formData = new FormData();
        formData.append("image", newFile);

        try {
          const response = await axiosInstance.put(`/student/${student_id}/update-photo/`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          if (response.status === 200) {
            Swal.fire({
              icon: 'success',
              title: 'Succès',
              text: "La photo de profil a été modifiée",
            });
            setImageUrl(response.data.image + `?timestamp=${new Date().getTime()}`);
          }
        } catch (error) {
          console.error(error);
          Swal.fire({
            icon: 'error',
            title: 'Erreur',
            text: "Une erreur s'est produite, veuillez réessayer",
          });
        } finally {
          setLoadingImage(false);
        }
      },
      error(err) {
        console.error(err.message);
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: "La compression de l'image a échoué, veuillez essayer une autre image",
        });
        setLoadingImage(false);
      },
    });
  };

  const handleFileChange = (event, student_id) => {
    const file = event.target.files[0];
    if (file) {
      handleEditPhoto(student_id, file);
    }
  };

  const unpaidMonths = subscriptionInfo.unpaid_months || 0;

  return (
    <div className="container-xxl position-relative bg-white d-flex p-0">
      <Sidebar />
      <div className="content">
        <Header />
        <div className="container-fluid pt-4 px-4">
          
          <div className="row g-4">
            {loading ? (
              <Spinner />
            ) : (
              <div className="col-sm-6 col-xl-6">
                <div className="bg-light rounded p-4 text-center position-relative">
                  <div className="position-relative d-inline-block">
                    {loadingImage ? (
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <img 
                        className="img-fluid rounded-circle mx-auto mb-4" 
                        src={imageUrl} 
                        style={{ width: "180px", height: "180px" }}
                        alt="Student"
                      />
                    )}
                    {!loadingImage && (
                      <>
                        <input 
                          type="file" 
                          id={`file-input-${student.id}`} 
                          style={{ display: 'none' }} 
                          onChange={(e) => handleFileChange(e, student.id)} 
                        />
                        <label 
                          htmlFor={`file-input-${student.id}`} 
                          className="position-absolute rounded-circle d-flex align-items-center justify-content-center" 
                          style={{ right: '10px', bottom: '10px', width: '40px', height: '40px', backgroundColor: '#007bff', cursor: 'pointer' }}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} size="lg" color="white" />
                        </label>
                      </>
                    )}
                  </div>
                  
                  <div className="my-1">
                    <h5 className="mb-1">{student.first_name}</h5>
                    <h5 className="mb-1">{student.last_name}</h5>
                    <h6 className="mb-1">{student.matricule}</h6>
                    <h6 className="mb-1">{student.classe_infos.name}</h6>
                    <h6 className="mb-1">{student.school_infos.name}</h6>
                  </div>

                  {subscriptionInfo && (
                    <>
                      <p>Statut Souscription : {subscriptionInfo.subscription_status === 'active' ? 'Actif' : 'Inactif'}</p>
                      {subscriptionInfo.subscription_status !== 'active' && firstNotificationMonth && (
                        <p>Mois à souscrire : {unpaidMonths} {unpaidMonths === 1 ? "mois" : "mois"}</p>
                      )}
                      {firstNotificationMonth === null && (
                        <p>Notifications pas encore envoyées.</p>
                      )}
                      
                    </>
                  )}

                  {firstNotificationMonth && subscriptionInfo.subscription_status !== 'active' && (
                    <div className="my-3">
                      <button
                        className="btn btn-success w-50"
                        onClick={(e) => {
                          handleActivateStudent(student);
                        }}
                      >
                        SOUSCRIRE
                      </button>
                    </div>
                  )}
                  <div>
                    <button
                      className="btn btn-danger w-50"
                      onClick={(e) => {
                        handleRemoveStudent(student.id);
                      }}
                    >
                      RETIRER
                    </button>
                  </div>
                    
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Mois de Souscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Mois impayés : {unpaidMonths}</p>
          <label htmlFor="additionalMonths" className="form-label">
            Ajouter des mois supplémentaires (optionnel) :
          </label>
          <select
            id="additionalMonths"
            className="form-select"
            value={additionalMonths}
            onChange={handleAdditionalMonthsChange}
          >
            <option value={0}>Aucun mois supplémentaire</option>
            {[...Array(8)].map((_, i) => (
              <option key={i} value={i + 1}>
                {i + 1} {i + 1 === 1 ? "mois" : "mois"}
              </option>
            ))}
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleProceedToPayment}>
            Continuer vers le paiement
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ParentStudentDetail;
