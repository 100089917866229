import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
    <div className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-light mb-4">Adresse</h4>
                    <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>Lambangny, Ratoma, Conakry-Guinée</p>
                    <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>+224 624 00 91 91</p>
                    <p className="mb-2"><i className="fa fa-envelope me-3"></i>shs@safehavensolving.com</p>
                    <div className="d-flex pt-2">
                        <Link className="btn btn-outline-light btn-social" to="/"><i className="fab fa-twitter"></i></Link>
                        <Link className="btn btn-outline-light btn-social" to="/"><i className="fab fa-facebook-f"></i></Link>
                        <Link className="btn btn-outline-light btn-social" to="/"><i className="fab fa-youtube"></i></Link>
                        <Link className="btn btn-outline-light btn-social" to="/"><i className="fab fa-linkedin-in"></i></Link>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-light mb-4">Téléchargez l'Application</h4>
                    <h6 className="text-light">Disponible sur iOS et Android</h6>
                    <p className="mb-4">Gratuit pour une période limitée !</p>
                    <p className="mb-0">Ne manquez pas cette opportunité de vous impliquer davantage dans l'éducation de votre enfant. </p>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-light mb-4">Avantages</h4>
                    <Link className="btn btn-link" to="/">Restez informés</Link>
                    <Link className="btn btn-link" to="/">Impliquez-vous activement</Link>
                    <Link className="btn btn-link" to="/">Favorisez la réussite</Link>
                    <Link className="btn btn-link" to="/">Gagnez du temps</Link>
                    <Link className="btn btn-link" to="/">Responsabilisation</Link>
                    <Link className="btn btn-link" to="/">Soutien accru</Link>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-light mb-4">Newslettre</h4>
                    <p>Pour ne rien rater de nos prochaines offres veuillez vous inscrire à notre newslettre.</p>
                    <div className="position-relative mx-auto" style={{ maxWidth: 400 + 'px' }}>
                        <input className="form-control border-0 w-100 py-3 ps-4 pe-5" type="text" placeholder="Votre email" />
                        <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">S'inscrire</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="copyright">
                <div className="row">
                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy; <Link className="border-bottom" href="#">School Liaison Guinée</Link>,  – L'avenir de l'éducation commence ici.
                    </div>
                    
                </div>
            </div>
        </div>
    </div>


    <a href="#" className="btn btn-lg btn-primary btn-lg-square rounded-0 back-to-top"><i className="bi bi-arrow-up"></i></a>
    </>
  );
}

export default Footer;
