import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAxios from "../../utils/useAxios";

import Header from "../../components/Agent/Header";
import Sidebar from "../../components/Agent/Sidebar";
import BackToTop from "../../components/BackToTop";
import { encryptParam } from "../../utils/crypto";
import Spinner from "../../components/Spinner";

function StudentManagement() {
  useEffect(() => {
    document.title = "Gestion Des Eleves";
  }, []);

  const [students, setStudents] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState("");
  const [loading, setLoading] = useState(false); 
  const [latestSchoolYear, setLatestSchoolYear] = useState(null);

  const axios = useAxios();

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true); 
      try {
        const schoolYearsResponse = await axios.get("/schoolyears/latest/");

        if (schoolYearsResponse) {
          const latestYear = schoolYearsResponse.data;
          setLatestSchoolYear(latestYear.id);
          
          const classesResponse = await axios.get(`/classe/?school_year_id=${latestYear.id}`);
          setClasses(classesResponse.data);
        }
      } catch (error) {
        // console.log(error);
      }
      setLoading(false);
    };

    fetchInitialData();
  }, []);

  const handleClassChange = (e) => {
    setSelectedClassId(e.target.value);
  };

  useEffect(() => {
    const fetchStudentsByClass = async () => {
      setLoading(true);
      if (selectedClassId) {
        try {
          const response = await axios.get(`/students-by-class/${selectedClassId}/`);
          if (response.data && response.data.students) {
            setStudents(response.data.students);
          } else {
            setStudents([]);
          }
        } catch (error) {
          // console.log(error);
          setStudents([]);
        }
      }
      setLoading(false);
    };

    fetchStudentsByClass();
  }, [selectedClassId]);

  const renderClasses = () => {
    return classes.map((classe) => (
      <option key={classe.id} value={classe.id}>
        {classe.name}
      </option>
    ));
  };

  return (
    <div className="container-xxl position-relative bg-white d-flex p-0">
      <Sidebar />

      <div className="content">
        <Header />

        <div className="container-fluid pt-4 px-4">
          <div className="row my-2">
            <div className="col-sm-12 col-xl-6">
              <h3 className="text-uppercase">
                Gestion des élèves
              </h3>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-sm-12 col-xl-6 mb-1">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Voir les élèves par classe
              </button>

              <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Veuillez selectionner une classe
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="bg-light rounded h-100 p-4">
                        <select
                          id="classe"
                          name="classe_id"
                          value={selectedClassId}
                          onChange={handleClassChange}
                          className="form-select mb-3"
                        >
                          <option value="">Choisir une classe</option>
                          {renderClasses()}
                        </select>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-primary" data-bs-dismiss="modal">OK</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-xl-6 mb-1">
              <Link to="add-student" className="btn btn-success text-uppercase">
                Ajouter Un Elève
              </Link>
            </div>
          </div>

          <div className="row g-4">
            <div className="col-sm-12 col-md-12 col-xl-12">
              <div className="h-100 bg-light rounded p-4">
                <div className="table-responsive">
                  {loading ? (
                    <Spinner />
                  ) : (
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">N°</th>
                          <th scope="col">Matricule</th>
                          <th scope="col">Prenom</th>
                          <th scope="col">Nom</th>
                          <th scope="col">Classe</th>
                          <th scope="col">Année Scolaire</th>
                          <th scope="col">Modifier</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(students) && students.length > 0 ? (
                          students.map((student, i) => (
                            <tr key={student.id}>
                              <td>{i + 1}</td>
                              <td>{student.matricule}</td>
                              <td>{student.first_name}</td>
                              <td>{student.last_name}</td>
                              <td>{student.classe_infos?.name || "N/A"}</td>
                              <td>
                                {student.school_year.start_date} - {student.school_year.end_date}
                              </td>
                              <td>
                                <Link to={`/dashboard/student/edit-student/${encryptParam(student.id)}`} className="btn btn-sm btn-warning">
                                  Modifier
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7" className="text-danger">Aucun élève trouvé dans cette classe.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <BackToTop />
      </div>
    </div>
  );
}

export default StudentManagement;
