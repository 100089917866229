import { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Parent/Header";
import Sidebar from "../../components/Parent/Sidebar";

function ParentDashboard() {
  useEffect(() => {
    document.title = "Dashboard Parent";
  });

  return (
    <div className="container-xxl position-relative bg-white d-flex p-0">
      <Sidebar />

      <div className="content">
        <Header />

        <div className="card m-4 p-4">
            <h3 className="text-capitalize text-center">
              <span className="text-uppercase">School Liaison Guinée</span>
              <br />Le Bon suivi pour un meilleur investissement
            </h3>
        </div>

          <div class="row g-4 m-4">
            <div class="col-sm-12 col-xl-12">
                <div class="bg-light rounded h-100 p-2">
                  <div class="d-flex">
                    <span className="ms-1"><Link to="/dashboard/parent/student" type="button" class="btn btn-success">MES ELEVES</Link></span>
                    <span className="ms-1"><Link to="/dashboard/parent/notification" type="button" class="btn btn-dark">NOTIFICATIONS</Link></span>
                    <span className="ms-1"><Link to="/dashboard/parent/statistic" type="button" class="btn btn-primary">STATISTIQUES</Link></span>
                    {/* <span className="ms-1"><Link to="/dashboard/parent/best/statistics" type="button" class="btn btn-primary">MEILLEURS ELEVES</Link></span> */}
                  </div>
                </div>
            </div>
          </div>

      </div>
    </div>
  );
}

export default ParentDashboard;
