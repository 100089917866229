import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useAxios from "../../utils/useAxios";
import moment from "moment";
import 'moment/locale/fr';

import Header from "../../components/Agent/Header";
import Sidebar from "../../components/Agent/Sidebar";
import BackToTop from "../../components/BackToTop";
import { decryptParam } from "../../utils/crypto";
import Spinner from "../../components/Spinner";

moment.locale('fr');

function NotificationDetailStudent() {
  useEffect(() => {
    document.title = "Detail Notifications";
  }, []);

  const [categoryNotifications, setCategoryNotifications] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [studentInfo, setStudentInfo] = useState(null);

  const { detail_id } = useParams();
  const axios = useAxios();


  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const studentIdDecrypt = decryptParam(detail_id);
        const studentId = parseInt(studentIdDecrypt, 10);
        const response = await axios.get(`/agents/notifications/students/?student_id=${studentId}`);
        setNotifications(response.data);
        if (response.data.length > 0) {
          setStudentInfo(response.data[0].student_infos);
        }
      } catch (error) {
        // console.log(error);
      }
    };

    fetchNotifications();
  }, [detail_id]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get("/category-notifications/");
        setCategoryNotifications(response.data);
      } catch (error) {
        // console.log(error);
      }
    };

    fetchCategories();
  }, []);

  if (notifications.length === 0) {
    return <Spinner />;
  }

  return (
    <div className="container-xxl position-relative bg-white d-flex p-0">
      <Sidebar />
      <div className="content">
        <Header />

        <div className="container-fluid pt-4 px-4">
          <div className="row g-4">

            {studentInfo && (
              <div className="col-sm-12 col-xl-12 mb-2">
                <div className="bg-light rounded p-2">
                  <div className="d-flex align-items-center">
                    <img
                      className="rounded-circle flex-shrink-0 me-3"
                      src={studentInfo.image}
                      alt="Student"
                      style={{ width: "80px", height: "80px" }}
                    />
                    <div>
                      <h5 className="mb-0 text-primary">{studentInfo.first_name}</h5>
                      <h5 className="mb-0 text-primary">{studentInfo.last_name}</h5>
                      <h6 className="mb-0">{studentInfo.classe_infos.name}</h6>
                      <h6 className="mb-0">{studentInfo.matricule}</h6>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {notifications.map((notification, index) => (
              <div key={index} className="col-sm-12 col-xl-12">
                <div className="bg-light rounded h-100 p-2">
                  <div className="border-bottom p-2 bg-white text-dark">
                    <div className="p-2 bd-highlight fw-bold text-capitalize">
                      {moment.utc(notification.date).format('LL')}
                    </div>
                    <div className="card p-2">
                      <div className="d-flex flex-wrap my-2">
                        {categoryNotifications
                          .filter(category => notification.categories.includes(category.id))
                          .map((category, i) => (
                            <div key={i} className="me-3">
                              {
                                category.id === 1 || category.id === 4 || category.id === 7 || category.id === 11
                                  ? <i className="fas fa-check-circle text-success"></i>
                                  : (
                                    category.id === 2 || category.id === 5 || category.id === 9 || category.id === 12
                                      ? <i className="fas fa-check-circle text-danger"></i>
                                      : <i className="fas fa-check-circle text-warning"></i>
                                  )
                              }
                              <label htmlFor={`category${category.id}`}>{category.name}</label>
                            </div>
                          ))
                        }
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <BackToTop />
    </div>
  );
}

export default NotificationDetailStudent;
