import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAxios from "../../utils/useAxios";

function Sidebar() {
  const axiosInstance = useAxios();
  const [userProfile, setUserProfile] = useState([]);
  const tokenString = localStorage.getItem("authTokens");

  if (tokenString) {
    try {
      const tokenObject = JSON.parse(tokenString);
      var role = tokenObject.user.role;
      var full_name = tokenObject.user.full_name;

    } catch (error) {
      // console.error("Invalid token format:", error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("/profil/");
        setUserProfile(response.data[0]);
      } catch (error) {
        // console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="sidebar pe-4 pb-3">
      <nav className="navbar bg-light navbar-light">
        <Link to="/dashboard/agent" className="navbar-brand mx-4 mb-3">
          <h4 className="text-primary text-center">
            SCHOOL LIAISON <br /> GUINEE
          </h4>
        </Link>
        <div className="d-flex align-items-center ms-4 mb-4">
          <div className="position-relative">
            <img
              className="rounded-circle"
              src={userProfile.image}
              alt=""
              style={{ width: 40 + "px", height: 40 + "px" }}
            />
            <div className="bg-success rounded-circle border border-2 border-white position-absolute end-0 bottom-0 p-1"></div>
          </div>
          <div className="ms-3">
            <h6 className="mb-0 text-uppercase">
              {full_name}
            </h6>
            <span className="fw-bold">{role}</span>
          </div>
        </div>
        <div className="navbar-nav w-100">
          <Link to="/dashboard/agent" className="nav-item nav-link active">
            <i className="fa fa-tachometer-alt me-2"></i>Dashboard
          </Link>
          <Link to="/dashboard/classe" className="nav-item nav-link">
            <i className="fa fa-school me-2"></i>Classes
          </Link>
          <Link to="/dashboard/student" className="nav-item nav-link">
            <i className="fa fa-graduation-cap me-2"></i>Elèves
          </Link>
          <Link to="/dashboard/teacher" className="nav-item nav-link">
            <i className="fa fa-users me-2"></i>Professeurs
          </Link>
          <Link to="/dashboard/notification" className="nav-item nav-link">
            <i className="fa fa-bell me-lg-2"></i>Notifications
          </Link>
        </div>
      </nav>
    </div>
  );
}

export default Sidebar;
