import React from 'react'

function Spinner() {
  return (
    <div 
        id="spinner" 
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
    >
        <div 
            class="spinner-border text-primary" 
            style={{ width: 3 +'rem', height: 3 + 'rem' }} role="status"
        >
            <span class="sr-only">Chargement...</span>
        </div>
    </div>
  )
}

export default Spinner