import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import api from "../utils/api";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const swal = require("sweetalert2");

function ResetPasswordPage() {
  const query = useQuery();
  const email = query.get("email");
  const code = query.get("code");

  const [new_password, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false); // État pour gérer le chargement

  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setPasswordError("");
    setConfirmPasswordError("");
    setMessage("");

    if (!new_password) {
      setPasswordError("Veuillez saisir un nouveau mot de passe");
      return;
    }

    if (new_password !== confirmPassword) {
      setConfirmPasswordError("Les mots de passe ne correspondent pas");
      return;
    }

    setIsLoading(true); // Activer l'indicateur de chargement

    try {
      const response = await api.post('/reset-password/email/', { email, code, new_password });

      if (response.data.success) {
        navigate(`/login`);
        swal.fire({
          title: "Mot de passe réinitialisé avec succès. Vous pouvez maintenant vous connecter.",
          icon: "success",
          toast: true,
          timer: 6000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      } else {
        setPasswordError(response.data.message || "Une erreur s'est produite. Veuillez réessayer.");
      }
    } catch (error) {
      console.error("Erreur de réinitialisation de mot de passe:", error);
      setPasswordError("Une erreur s'est produite. Veuillez réessayer.");
    } finally {
      setIsLoading(false); // Désactiver l'indicateur de chargement après la tentative de réinitialisation
    }
  };

  return (
    <div>
      <section className="vh-100">
        <div className="container py-3 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col col-xl-10">
              <div className="card" style={{ borderRadius: "1rem" }}>
                <div className="row g-0">
                  <div className="col-md-6 col-lg-5 d-none d-md-block">
                    <img
                      src="img/login.webp"
                      alt="login form"
                      className="img-fluid"
                      style={{ borderRadius: "1rem 0 0 1rem" }}
                    />
                  </div>
                  <div className="col-md-6 col-lg-7 d-flex align-items-center">
                    <div className="card-body p-4 p-lg-5 text-black">
                      <form onSubmit={handleResetPassword}>
                        <div className="d-flex align-items-center mb-3 pb-1">
                          <span className="h4 fw-bold mb-0 text-uppercase">
                            Nouveau Mot de Passe
                          </span>
                        </div>
                        <h5 className="fw-normal mb-3 pb-3" style={{ letterSpacing: 1 }}>
                          Entrez un nouveau mot de passe pour votre compte.
                        </h5>
                        <div className="form-outline mb-4">
                          <label className="form-label" htmlFor="password">
                            Nouveau mot de passe
                          </label>
                          <input
                            type="password"
                            id="password"
                            className={`form-control form-control-lg ${passwordError ? "is-invalid" : ""}`}
                            name="new_password"
                            placeholder="Nouveau mot de passe"
                            onChange={(e) => setNewPassword(e.target.value)}
                            disabled={isLoading} // Désactiver le champ pendant le chargement
                          />
                          {passwordError && <div className="text-danger">{passwordError}</div>}
                        </div>
                        <div className="form-outline mb-4">
                          <label className="form-label" htmlFor="confirmPassword">
                            Confirmez le mot de passe
                          </label>
                          <input
                            type="password"
                            id="confirmPassword"
                            className={`form-control form-control-lg ${confirmPasswordError ? "is-invalid" : ""}`}
                            name="confirmPassword"
                            placeholder="Confirmer le mot de passe"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            disabled={isLoading} // Désactiver le champ pendant le chargement
                          />
                          {confirmPasswordError && <div className="text-danger">{confirmPasswordError}</div>}
                        </div>
                        {message && <div className="text-success mb-3">{message}</div>}
                        <div className="pt-1 mb-4">
                          <button
                            className="btn btn-dark btn-lg btn-block"
                            type="submit"
                            disabled={isLoading} // Désactiver le bouton pendant le chargement
                          >
                            {isLoading ? (
                              <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only">Chargement...</span>
                              </div>
                            ) : (
                              "Réinitialiser le mot de passe"
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ResetPasswordPage;
