import React from "react";

export const VerificationModal = ({ changedStudents, categoryNotifications }) => {
  const renderChangedStudents = () => {
    return changedStudents.map(({ student, categories }) => (
      <div key={student.id}>
        <h6>{student.matricule} - {student.first_name} {student.last_name}</h6>
        <ul>
          {categories.map((categoryId) => (
            <li key={categoryId}>
              {categoryNotifications.find((c) => c.id === categoryId)?.name}
            </li>
          ))}
        </ul>
        <hr className="compact-hr"/>
      </div>
    ));
  };

  return (
    <div className="modal fade" id="verificationModal" tabIndex="-1" aria-labelledby="verificationModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="verificationModalLabel">Liste des élèves dont les infos ont changés</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="bg-light rounded h-100 p-2">
              {renderChangedStudents()}
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">OK</button>
          </div>
        </div>
      </div>
    </div>
  );
};
