import CryptoJS from 'crypto-js';

const secretKey = 'MaFonctionDeChiffrementUrlDate03/07/2024'; 

export function encryptParam(param) {
  const encrypted = CryptoJS.AES.encrypt(param.toString(), secretKey).toString();
  return encodeURIComponent(encrypted); 
}

export function decryptParam(encryptedParam) {
  const bytes = CryptoJS.AES.decrypt(decodeURIComponent(encryptedParam), secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
}
