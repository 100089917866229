import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";

function LoginPage() {
  useEffect(() => {
    document.title = "Connexion | School Liaison Guinée";
  }, []);

  const { loginUser } = useContext(AuthContext);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false); // État pour gérer le chargement

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Réinitialiser les erreurs
    setUsernameError("");
    setPasswordError("");

    // Vérifier les champs
    let valid = true;

    if (!username) {
      setUsernameError("Veuillez saisir votre nom d'utilisateur");
      valid = false;
    }
    if (!password) {
      setPasswordError("Veuillez saisir votre mot de passe");
      valid = false;
    }

    if (!valid) {
      return;
    }

    setIsLoading(true); // Activer l'indicateur de chargement

    try {
      await loginUser(username, password);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // Désactiver l'indicateur de chargement après la tentative de connexion
    }
  };

  return (
    <div>
      <section className="vh-100">
        <div className="container py-3 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col col-xl-10">
              <div className="card" style={{ borderRadius: "1rem" }}>
                <div className="row g-0">
                  <div className="col-md-6 col-lg-5 d-none d-md-block">
                    <img
                      src="img/login.webp"
                      alt="login form"
                      className="img-fluid"
                      style={{ borderRadius: "1rem 0 0 1rem" }}
                    />
                  </div>
                  <div className="col-md-6 col-lg-7 d-flex align-items-center">
                    <div className="card-body p-4 p-lg-5 text-black">
                      <form onSubmit={handleSubmit}>
                        <div className="d-flex align-items-center mb-3 pb-1">
                          <span className="h3 fw-bold mb-0 text-uppercase">
                            School Liaison Guinée
                          </span>
                        </div>
                        <h5
                          className="fw-normal mb-3 pb-3"
                          style={{ letterSpacing: 1 }}
                        >
                          Veuillez Vous Connecter
                        </h5>
                        <div className="form-outline mb-4">
                          <label className="form-label" htmlFor="username">
                            Nom d'utilisateur ou Email
                          </label>
                          <input
                            type="text"
                            id="username"
                            className={`form-control form-control-lg ${usernameError ? "is-invalid" : ""}`}
                            name="username"
                            placeholder="Votre nom d'utilisateur ou email"
                            onChange={(e) => setUsername(e.target.value)}
                            disabled={isLoading} // Désactiver le champ pendant le chargement
                          />
                          {usernameError && <div className="text-danger">{usernameError}</div>}
                        </div>
                        <div className="form-outline mb-4">
                          <label className="form-label" htmlFor="password">
                            Mot de passe
                          </label>
                          <input
                            type="password"
                            id="password"
                            className={`form-control form-control-lg ${passwordError ? "is-invalid" : ""}`}
                            name="password"
                            placeholder="Votre mot de passe"
                            onChange={(e) => setPassword(e.target.value)}
                            disabled={isLoading} // Désactiver le champ pendant le chargement
                          />
                          {passwordError && <div className="text-danger">{passwordError}</div>}
                        </div>
                        <div className="mb-2">
                          <Link to="/forgotpassword" style={{ color: "#393f81" }}>
                            Mot de passe oublié ?
                          </Link>
                        </div>
                        <div className="pt-1 mb-4">
                          <button
                            className="btn btn-dark btn-lg btn-block"
                            type="submit"
                            disabled={isLoading} // Désactiver le bouton pendant le chargement
                          >
                            {isLoading ? (
                              <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only">Chargement...</span>
                              </div>
                            ) : (
                              "Connexion"
                            )}
                          </button>
                        </div>
                        <p className="mb-5 pb-lg-2" style={{ color: "#393f81" }}>
                          Vous n'avez pas de compte ?
                          <Link to="/register" style={{ color: "#393f81" }}>
                            Créer Un Compte
                          </Link>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default LoginPage;
