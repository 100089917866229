import React from 'react';

const About = () => {
  return (
    <div className="container-xxl py-5">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    <h6 className="text-secondary text-uppercase">Bienvenue sur School Liaison Guinée !</h6>
                    <h1 className="mb-4">Votre Compagnon de Suivi Scolaire de votre Enfant</h1>
                    <p className="mb-4">Chez School Liaison Guinée, nous comprenons l'importance de l'implication des parents dans la réussite scolaire de leurs enfants. Notre application est conçue pour vous fournir un aperçu complet et instantané du parcours éducatif de votre enfant, le tout à portée de main.</p>
                    <p className="fw-medium text-primary"><i className="fa fa-check text-success me-3"></i>Surveillez les progrès de votre enfant en temps réel</p>
                    <p className="fw-medium text-primary"><i className="fa fa-check text-success me-3"></i>Restez informé des notes, devoirs et annonces de votre enfant</p>
                    <p className="fw-medium text-primary"><i className="fa fa-check text-success me-3"></i>Communiquez facilement avec les enseignants et l'école</p>
                    <p className="fw-medium text-primary"><i className="fa fa-check text-success me-3"></i>Impliquez-vous activement dans l'éducation de votre enfant</p>
                    <div className="bg-primary d-flex align-items-center p-4 mt-5">
                        <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white" style={{ width: 60 + 'px', height: 60 + 'px' }}>
                            <i className="fab fa-google-play fa-2x text-primary"></i>
                        </div>
                        <div className="ms-3">
                            <p className="fs-5 fw-medium mb-2 text-white">Télécharger l'application Mobile sur</p>
                            <h3 className="m-0 text-secondary">Play Store & App Store</h3>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 pt-4" style={{ minHeight: 500 +'px' }}>
                    <div className="position-relative h-100 wow fadeInUp" data-wow-delay="0.5s">
                        <img className="position-absolute img-fluid w-100 h-100" src="img/about-1.jpg" style={{ objectFit: 'cover', padding: 0 + 0 + 50 +'px' + 100 + 'px' }} alt="" />
                        <img className="position-absolute start-0 bottom-0 img-fluid bg-white pt-2 pe-2 w-50 h-50" src="img/about-2.jpg" style={{ objectFit: 'cover' }} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default About;
