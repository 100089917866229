import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Spinner from "../components/Spinner";

function Dashboard() {
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    const tokenString = localStorage.getItem("authTokens");
    if (tokenString) {
      try {
        const tokenObject = JSON.parse(tokenString);
        const role = tokenObject.user.role; 

        if (role === "agent") {
          setRedirectTo("/dashboard/agent");
        } else if (role === "parent") {
          setRedirectTo("/dashboard/parent");
        }
      } catch (error) {
        // console.error("Invalid token format:", error);
      }
    }
  }, []);

  if (redirectTo) {
    return <Navigate to={redirectTo} replace />;
  }

  return <Spinner />;
}

export default Dashboard;
