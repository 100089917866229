import React from "react";
import { Routes as Switch, Route } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";

import HomePage from "./views/HomePage";
import LoginPage from "./views/LoginPage";
import RegisterPage from "./views/RegisterPage";
import NotFoundPage from "./views/NotFoundPage";

import Dashboard from "./views/Dashboard";
import AgentDashboard from "./views/Agent/AgentDashboard";
import ParentDashboard from "./views/Parent/ParentDashboard";
import AddClasse from "./views/Agent/AddClasse";
import ClasseManagement from "./views/Agent/ClasseManagement";
import NotificationManagement from "./views/Agent/NotificationManagement";
import SendNotification from "./views/Agent/SendNotification";
import NotificationDetailStudent from "./views/Agent/NotificationDetailStudent";
import ParentNotificationManagement from "./views/Parent/ParentNotificationManagement";
import ParentNotificationDetail from "./views/Parent/ParentNotificationDetail";
import AddStudent from "./views/Agent/AddStudent";
import StudentManagement from "./views/Agent/StudentManagement";
import TeacherManagement from "./views/Agent/TeacherManagement";
import AddTeacher from "./views/Agent/AddTeacher";
import ParentStudentManagement from "./views/Parent/ParentStudentManagement";
import ParentAddStudent from "./views/Parent/ParentAddStudent";
import EditClasse from "./views/Agent/EditClasse";
import EditStudent from "./views/Agent/EditStudent";
import EditTeacher from "./views/Agent/EditTeacher";
import ProfileAgent from "./views/Agent/Profile";
import ForgotPasswordPage from "./views/ForgotPasswordPage";
import VerifyCodePage from "./views/VerifyCodePage";
import ResetPasswordPage from "./views/ResetPasswordPage";
import ParentStatisticManagement from "./views/Parent/ParentStatisticManagement";
import ParentStatisticDetail from "./views/Parent/ParentStatisticDetail";
import ProfileParent from "./views/Parent/Profile";
import PaymentPage from "./views/Parent/PaymentPage";
import ParentNotificationStudent from "./views/Parent/ParentNotificationStudent";
import ParentStudentDetail from "./views/Parent/ParentStudentDetail";
import BestStudentStatistics from "./views/Parent/BestStudentStatistics";
import BestStudentStatisticsDetails from "./views/Parent/BestStudentStatisticsDetails";


function App() {
  return (
    <AuthProvider>
      <Switch>
        <Route exact path="/dashboard" element={<PrivateRoute />}>
          {/* Routes reservés aux agents  */}
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/dashboard/agent" element={<AgentDashboard />} />
          <Route exact path="/dashboard/agent/profile" element={<ProfileAgent />} />
          <Route exact path="/dashboard/classe" element={<ClasseManagement />} />
          <Route exact path="/dashboard/classe/add-classe" element={<AddClasse />} />
          <Route exact path="/dashboard/classe/edit-classe/:id" element={<EditClasse />} />
          <Route exact path="/dashboard/student" element={<StudentManagement />} />
          <Route exact path="/dashboard/student/add-student" element={<AddStudent />} />
          <Route exact path="/dashboard/student/edit-student/:id" element={<EditStudent />} />
          <Route exact path="/dashboard/teacher" element={<TeacherManagement />} />
          <Route exact path="/dashboard/teacher/add-teacher" element={<AddTeacher />} />
          <Route exact path="/dashboard/teacher/edit-teacher/:id" element={<EditTeacher />} />
          <Route exact path="/dashboard/notification" element={<NotificationManagement />} />
          <Route exact path="/dashboard/notification/send-notification" element={<SendNotification />} />
          <Route exact path="/dashboard/notification/student/details/:detail_id" element={<NotificationDetailStudent />} />
          
          {/* Routes reservés aux parents  */}
          <Route exact path="/dashboard/parent" element={<ParentDashboard />} />
          <Route exact path="/dashboard/parent/profile" element={<ProfileParent />} />
          <Route exact path="/dashboard/parent/notification" element={<ParentNotificationManagement />} />
          <Route exact path="/dashboard/parent/notification/student/:id" element={<ParentNotificationStudent />} />
          <Route exact path="/dashboard/parent/notification/details/:id" element={<ParentNotificationDetail />} />
          <Route exact path="/dashboard/parent/statistic" element={<ParentStatisticManagement />} />
          <Route exact path="/dashboard/parent/statistic/:id" element={<ParentStatisticDetail />} />
          <Route exact path="/dashboard/parent/student" element={<ParentStudentManagement />} />
          <Route exact path="/dashboard/parent/student/:id" element={<ParentStudentDetail />} />
          <Route exact path="/dashboard/parent/student/add-student" element={<ParentAddStudent />} />
          <Route exact path="/dashboard/payment" element={<PaymentPage />} />


          <Route exact path="/dashboard/parent/best/statistics" element={<BestStudentStatistics />} />
          <Route exact path="/dashboard/parent/best/statistic/:id" element={<BestStudentStatisticsDetails />} />
        </Route>

        <Route path="/"         element={<HomePage />} exact />
        <Route path="/login"    element={<LoginPage />} exact />
        <Route path="/register" element={<RegisterPage />} exact />
        <Route path="/forgotpassword" element={<ForgotPasswordPage />} exact />
        <Route path="/verify-reset-code" element={<VerifyCodePage />} exact />
        <Route path="/resetpassword" element={<ResetPasswordPage />} exact />

        {/* Other routes  */}
        <Route path="*" element={<NotFoundPage />} />
      </Switch>
    </AuthProvider>
  );
}

export default App;
