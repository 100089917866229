import { useEffect } from "react";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Topbar from "../components/Topbar";
import Testimonials from "../components/Testimonial";
import Services from "../components/Services";
import ServiceComponent from "../components/ServiceComponent";
import About from "../components/About";
import Agent from "../components/Agent";
import Carousel from "../components/Carousel";

function HomePage() {
  useEffect(() => {
    document.title = "Accueil | School Liaison Guinée";
  });

  return (
    <>
      <Topbar />

      <Navbar />

      <Carousel />

      <ServiceComponent />

      <About />
    
      <Services />

      <Agent />

      <Testimonials />

      <Footer />
    </>
  );
}

export default HomePage;
