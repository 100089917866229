import React from 'react';
import { CheckboxComponent } from './CheckboxComponent';

export const StudentWithCategories = ({ student, categoryNotifications, selectedNotifications, handleNotificationChange }) => {

  // Fonction pour diviser les catégories en colonnes
  const getColumns = (items, columns) => {
    const perColumn = Math.ceil(items.length / columns);
    return Array.from({ length: columns }, (v, i) => items.slice(i * perColumn, i * perColumn + perColumn));
  };

  // Diviser les catégories en 4 colonnes
  const columns = getColumns(categoryNotifications, 4);

  return (
    <div className="row g-4">
      <div className="col-md-2">
        <h6>{student.matricule}</h6>
        <h5>{student.first_name} {student.last_name}</h5>
      </div>
      <div className="col-md-10">
        <div className="row">
          {columns.map((column, columnIndex) => (
            <div key={columnIndex} className="col-md-3">
              {column.map((category) => (
                <div key={category.id} className="mb-1">
                  <CheckboxComponent
                    studentId={student.id}
                    category={category}
                    selectedNotifications={selectedNotifications}
                    handleNotificationChange={handleNotificationChange}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
