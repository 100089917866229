import React from "react";

export const ClassSelectionModal = ({ selectedClassId, handleClassChange, classes }) => {
  const renderClasses = () => {
    return classes.map((classe) => (
      <option key={classe.id} value={classe.id}>
        {classe.name}
      </option>
    ));
  };

  return (
    <div className="modal fade" id="classModal" tabIndex="-1" aria-labelledby="classModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="classModalLabel">Veuillez selectionner une classe</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="bg-light rounded h-100 p-4">
              <select id="classe" name="classe_id" value={selectedClassId} onChange={handleClassChange} className="form-select mb-3">
                <option value="">Choisir une classe</option>
                {renderClasses()}
              </select>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">OK</button>
          </div>
        </div>
      </div>
    </div>
  );
};
