import React from 'react';

const testimonials = [
  {
    id: 1,
    text: "Grâce à School Liaison, je me sens plus impliqué dans la scolarité de ma fille. Je peux facilement suivre ses progrès et communiquer avec ses enseignants. C'est un véritable atout !",
    image: 'img/testimonial-1.jpg', 
    name: 'Mariame Sow',
    profession: 'Commerçante',
  },
  {
    id: 2,
    text: "L'application m'a permis de détecter les difficultés de mon fils en mathématiques rapidement, ce qui nous a aidés à prendre des mesures correctives à temps.",
    image: 'img/testimonial-2.jpg',
    name: 'Jean Kamano',
    profession: 'Administrateur',
  },
  {
    id: 3,
    text: "Grâce à School Liaison, je me sens plus impliqué dans la scolarité de ma fille. Je peux facilement suivre ses progrès et communiquer avec ses enseignants. C'est un véritable atout !",
    image: 'img/testimonial-1.jpg',
    name: 'Mariame Sow',
    profession: 'Commerçante',
  },
  {
    id: 4,
    text: "L'application m'a permis de détecter les difficultés de mon fils en mathématiques rapidement, ce qui nous a aidés à prendre des mesures correctives à temps.",
    image: 'img/testimonial-2.jpg',
    name: 'Jean Kamano',
    profession: 'Administrateur',
  },
];

function Testimonials() {
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="text-center">
          <h6 className="text-secondary text-uppercase">Témoignages</h6>
          <h1 className="mb-5">Nos Utilisateurs Disent !</h1>
        </div>

        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
          {testimonials.map((testimonial) => (
            <div key={testimonial.id} className="col">
              <div className="card text-center h-100 border-0 bg-light">
                <div className="card-body">
                  <p className="card-text">{testimonial.text}</p>
                </div>
                <div className="card-footer border-0 bg-transparent">
                  <img
                    src={testimonial.image}
                    alt={`Client ${testimonial.id}`}
                    className="rounded-circle border bg-light p-2 mb-2"
                    style={{ width: '80px', height: '80px' }}
                  />
                  <div className="mb-2">
                    <i className="fa fa-star text-secondary"></i>
                    <i className="fa fa-star text-secondary"></i>
                    <i className="fa fa-star text-secondary"></i>
                    <i className="fa fa-star text-secondary"></i>
                    <i className="fa fa-star text-secondary"></i>
                  </div>
                  <h5 className="mb-1">{testimonial.name}</h5>
                  <p className="m-0">{testimonial.profession}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
