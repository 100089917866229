import { useEffect, useState } from "react";
import useAxios from "../../utils/useAxios";
import Header from "../../components/Agent/Header";
import Sidebar from "../../components/Agent/Sidebar";
import Menu from "../../components/Menu";

function AgentDashboard() {
    useEffect(() => {
      document.title = "Tableau De Bord";
    });

    const tokenString = localStorage.getItem("authTokens");
    const axiosInstance = useAxios();

    if (tokenString) {
      try {
        const tokenObject = JSON.parse(tokenString);
        var full_name = tokenObject.user.full_name;

      } catch (error) {
        // console.error("Invalid token format:", error);
      }
    }

    const [school, setSchool] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axiosInstance.get("/school/");
          setSchool(response.data);
        } catch (error) {
          // console.log(error);
        }
      };

      fetchData();
    }, []);

    return (
      <div className="container-xxl position-relative bg-white d-flex p-0">
        <Sidebar />

        <div className="content">
          <Header />

          <div className="card m-4 p-4">
            <h3>
              Bienvenu (e) <span className="text-uppercase"> {full_name} </span>
            </h3>
            {school.map((item, i) => (
              <div key={item.id}>
                <h4>
                  Ecole : <span className="text-uppercase"> {item.name} </span>
                </h4>
                <h5>
                  Adresse : <span className="text-uppercase"> {item.address} </span>
                </h5>
              </div>
            ))}
          </div>

          <Menu />
        </div>
      </div>
    );
}

export default AgentDashboard;
