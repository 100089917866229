import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import useAxios from "../../utils/useAxios";

function Header() {
  const { logoutUser } = useContext(AuthContext);
  const axiosInstance = useAxios();
  const [userProfile, setUserProfile] = useState([]);

  const tokenString = localStorage.getItem("authTokens");
  if (tokenString) {
    try {
      const tokenObject = JSON.parse(tokenString);
      var full_name = tokenObject.user.full_name;

    } catch (error) {
      // console.error("Invalid token format:", error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("/profil/");
        setUserProfile(response.data[0]);
      } catch (error) {
        // console.log(error);
      }
    };

    fetchData();
  }, []);


  return (
    <div>
      <nav className="navbar navbar-expand bg-light navbar-light sticky-top px-4 py-0">
        {/* <Link href="#" className="navbar-brand d-flex d-lg-none me-4">
          <h2 className="text-primary mb-0">
            <i className="fa fa-hashtag"></i>
          </h2>
        </Link> */}
        {/* <Link href="#" className="sidebar-toggler flex-shrink-0">
          <i className="fa fa-bars"></i>
        </Link> */}

        <div className="navbar-nav align-items-center ms-auto">
          <div className="nav-item dropdown">
            <Link
              to="/dashboard"
              className="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              <img
                className="rounded-circle me-lg-2"
                src={userProfile.image}
                alt=""
                style={{ width: 40 + "px", height: 40 + "px" }}
              />
              <span className="d-none d-lg-inline-flex">
                {full_name}
              </span>
            </Link>
            <div className="dropdown-menu dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom m-0">
              <Link to="/dashboard/agent/profile/" className="dropdown-item">
                Profile
              </Link>
              <Link
                onClick={logoutUser}
                style={{ cursor: "pointer" }}
                className="dropdown-item"
              >
                Se Deconnecter
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
