import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";

function RegisterPage() {
  useEffect(() => {
    document.title = "Inscription | School Liaison Guinée";
  }, []);

  const [fullName, setFullName] = useState("");
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [email, setEmail] = useState("");

  const [fullNameError, setFullNameError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [password2Error, setPassword2Error] = useState("");
  const [isLoading, setIsLoading] = useState(false); // État pour gérer le chargement

  const { registerUser } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Réinitialiser les erreurs
    setFullNameError('');
    setUsernameError('');
    setPhoneError('');
    setPasswordError('');
    setPassword2Error('');

    // Vérifier les champs
    let valid = true;

    if (!fullName) {
      setFullNameError("Veuillez saisir votre nom complet");
      valid = false;
    }
    if (!phone || phone.length < 9) {
      setPhoneError("Veuillez saisir un numéro de téléphone valide !");
      valid = false;
    }
    if (!username || username.length < 5) {
      setUsernameError("Le nom d'utilisateur doit être supérieur à 5 caractères");
      valid = false;
    }
    if (!password || password.length < 6) {
      setPasswordError("Le mot de passe est trop faible");
      valid = false;
    }
    if (password !== password2) {
      setPassword2Error("Les mots de passe ne correspondent pas");
      valid = false;
    }

    if (!valid) {
      return;
    }

    setIsLoading(true); // Activer l'indicateur de chargement

    try {
      await registerUser(fullName, username, phone, password, password2, email);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // Désactiver l'indicateur de chargement après la tentative d'inscription
    }
  };

  return (
    <div>
      <section className="vh-100">
        <div className="container py-4 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col col-xl-10">
              <div className="card" style={{ borderRadius: "1rem" }}>
                <div className="row g-0">
                  <div className="col-md-6 col-lg-5 d-none d-md-block">
                    <img
                      src="img/login.webp"
                      alt="login form"
                      className="img-fluid"
                      style={{ borderRadius: "1rem 0 0 1rem" }}
                    />
                  </div>
                  <div className="col-md-6 col-lg-7 d-flex align-items-center">
                    <div className="card-body p-4 p-lg-5 text-black">
                      <form onSubmit={handleSubmit}>
                        <div className="d-flex align-items-center mb-3 pb-1">
                          <span className="h3 fw-bold mb-0 text-uppercase">
                            <b>School Liaison Guinée</b>
                          </span>
                        </div>
                        <h5
                          className="fw-normal mb-3 pb-3"
                          style={{ letterSpacing: 1 }}
                        >
                          Créer Votre Compte
                        </h5>
                        <div className="form-outline mb-3">
                          <input
                            type="text"
                            id="fullName"
                            className={`form-control form-control-lg ${fullNameError ? 'is-invalid' : ''}`}
                            placeholder="Nom Complet"
                            onChange={(e) => setFullName(e.target.value)}
                            disabled={isLoading} // Désactiver le champ pendant le chargement
                          />
                          {fullNameError && <div className="text-danger">{fullNameError}</div>}
                        </div>
                        <div className="form-outline mb-3">
                          <input
                            type="text"
                            id="username"
                            className={`form-control form-control-lg ${usernameError ? 'is-invalid' : ''}`}
                            placeholder="Nom d'utilisateur"
                            onChange={(e) => setUsername(e.target.value)}
                            disabled={isLoading} // Désactiver le champ pendant le chargement
                          />
                          {usernameError && <div className="text-danger">{usernameError}</div>}
                        </div>
                        <div className="form-outline mb-3">
                          <input
                            type="text"
                            id="phone"
                            className={`form-control form-control-lg ${phoneError ? 'is-invalid' : ''}`}
                            placeholder="Téléphone"
                            onChange={(e) => setPhone(e.target.value)}
                            disabled={isLoading} // Désactiver le champ pendant le chargement
                          />
                          {phoneError && <div className="text-danger">{phoneError}</div>}
                        </div>
                        <div className="form-outline mb-3">
                          <input
                            type="email"
                            id="email"
                            className="form-control form-control-lg"
                            placeholder="Email (facultatif)"
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={isLoading} // Désactiver le champ pendant le chargement
                          />
                        </div>
                        <div className="form-outline mb-3">
                          <input
                            type="password"
                            id="password"
                            className={`form-control form-control-lg ${passwordError ? 'is-invalid' : ''}`}
                            placeholder="Mot de passe"
                            onChange={(e) => setPassword(e.target.value)}
                            disabled={isLoading} // Désactiver le champ pendant le chargement
                          />
                          {passwordError && <div className="text-danger">{passwordError}</div>}
                        </div>
                        <div className="form-outline mb-3">
                          <input
                            type="password"
                            id="password2"
                            className={`form-control form-control-lg ${password2Error ? 'is-invalid' : ''}`}
                            placeholder="Confirmer votre mot de passe"
                            onChange={(e) => setPassword2(e.target.value)}
                            disabled={isLoading} // Désactiver le champ pendant le chargement
                          />
                          {password2Error && <div className="text-danger">{password2Error}</div>}
                        </div>
                        <div className="pt-1 mb-3">
                          <button
                            className="btn btn-dark btn-lg btn-block"
                            type="submit"
                            disabled={isLoading} // Désactiver le bouton pendant le chargement
                          >
                            {isLoading ? (
                              <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only">Chargement...</span>
                              </div>
                            ) : (
                              "S'inscrire"
                            )}
                          </button>
                        </div>
                        <p
                          className="mb-5 pb-lg-2"
                          style={{ color: "#393f81" }}
                        >
                          Vous avez déjà un compte ?  
                          <Link to="/login" style={{ color: "#393f81" }}>
                             Connectez-vous 
                          </Link>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default RegisterPage;
