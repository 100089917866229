import { createContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import config from "../utils/config";

const swal = require("sweetalert2");
const AuthContext = createContext();

const baseURL = config.apiBaseUrl;

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );

  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwtDecode(localStorage.getItem("authTokens"))
      : null
  );

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const loginUser = async (identifier, password) => {
    const response = await fetch(
      baseURL + "/signin/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          identifier,
          password,
        }),
      }
    );
  
    const data = await response.json();
    // console.log("Login response data:", data);
  
    if (response.status === 200 && data.tokens && typeof data.tokens.access === 'string') {
      // Restructuring the response to match the expected format
      const formattedTokens = {
        access: data.tokens.access,
        refresh: data.tokens.refresh,
      };
  
      try {
        const authData = {
          tokens: formattedTokens,
          user: data.user
        };
  
        setAuthTokens(authData);
        const decodedUser = jwtDecode(authData.tokens.access);
        // console.log("Decoded user after login:", decodedUser);
        setUser(decodedUser);
        localStorage.setItem("authTokens", JSON.stringify(authData));
        navigate("/dashboard");
        swal.fire({
          title: "Connecté Avec Succès",
          icon: "success",
          toast: true,
          timer: 6000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      } catch (error) {
        // console.error("Error decoding token:", error);
      }
    } else {
      // console.error("Invalid login response or server issue", response.status, data);
      swal.fire({
        title: "Nom d'utilisateur ou mot de passe incorrect",
        icon: "error",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  }; 

  const registerUser = async (
    full_name,
    username,
    phone,
    password,
    password2,
    email
  ) => {
    const requestBody = {
      full_name,
      username,
      phone,
      password,
      password2,
    };

    if (email) {
      requestBody.email = email;
    }

    const response = await fetch(
      baseURL + "/signup/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody)
      }
    );
    if (response.status === 200) {
      navigate("/login");
      swal.fire({
        title: "Inscription réussie, connectez-vous maintenant.",
        icon: "success",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } else {
      // console.log(response.status);
      // console.log("there was a server issue");
      swal.fire({
        title: "Le nom d'utilisateur et le numéro de téléphone existent déjà.",
        icon: "error",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("authTokens");
    navigate("/login");
    swal.fire({
      title: "Vous avez été déconnecté !",
      icon: "success",
      toast: true,
      timer: 6000,
      position: "top-right",
      timerProgressBar: true,
      showConfirmButton: false,
    });
  };

  const contextData = {
    user,
    setUser,
    authTokens,
    setAuthTokens,
    registerUser,
    loginUser,
    logoutUser,
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwtDecode(authTokens.tokens.access));
    }
    setLoading(false);
  }, [authTokens, loading]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
