import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useAxios from "../../utils/useAxios";
import moment from "moment";
import 'moment/locale/fr'; 
import { encryptParam } from "../../utils/crypto";

import Header from "../../components/Agent/Header";
import Sidebar from "../../components/Agent/Sidebar";
import BackToTop from "../../components/BackToTop";
import Spinner from "../../components/Spinner";

moment.locale('fr'); 

function NotificationManagement() {
  useEffect(() => {
    document.title = "Gestion Des Notifications";
  }, []);

  const [notifications, setNotifications] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState("");
  const [selectedClassName, setSelectedClassName] = useState(""); // Ajouté pour le nom de la classe
  const [latestSchoolYear, setLatestSchoolYear] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const axios = useAxios();

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true); 
      try {
        const schoolYearsResponse = await axios.get("/schoolyears/");
        const schoolYears = schoolYearsResponse.data;

        if (schoolYears.length > 0) {
          const latestYear = schoolYears[schoolYears.length - 1];
          setLatestSchoolYear(latestYear.id);
          
          const classesResponse = await axios.get(`/classe/?school_year_id=${latestYear.id}`);
          setClasses(classesResponse.data);
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };

    fetchInitialData();
  }, []);

  const handleClassChange = (e) => {
    const selectedClassId = e.target.value;
    setSelectedClassId(selectedClassId);
    const selectedClass = classes.find(classe => classe.id.toString() === selectedClassId);
    setSelectedClassName(selectedClass ? selectedClass.name : ""); // Mettre à jour le nom de la classe
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      setIsLoading(true);
      setError(null);

      if (selectedClassId) {
        try {
          const endpoint = `/agents/students/notifications-by-class/?class_id=${selectedClassId}`;
  
          const response = await axios.get(endpoint);
  
          if (response.status === 200) {
            setNotifications(response.data);
          } else {
            setNotifications([]);
            setError("Aucune notification disponible.");
          }
        } catch (error) {
          console.error(error);
          setError("Aucune notification disponible.");
          setNotifications([]);
        } finally {
          setIsLoading(false);
        }
      }

    };

    if (latestSchoolYear) {
      fetchNotifications();
    }
  }, [selectedClassId, latestSchoolYear]);

  const renderClasses = () => {
    return classes.map((classe) => (
      <option key={classe.id} value={classe.id}>
        {classe.name}
      </option>
    ));
  };

  return (
    <div className="container-xxl position-relative bg-white d-flex p-0">
      <Sidebar />

      <div className="content">
        <Header />

        <div className="container-fluid pt-4 px-4">
          <div className="row my-1">
            <div className="col-sm-12 col-xl-6">
              <h3 className="text-uppercase">
                Gestion des notifications
              </h3>
            </div>
          </div>

          <div className="row my-1">
            <div className="col-sm-12 col-xl-6 mb-1">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Voir les notifications par classe
              </button>

              <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Veuillez sélectionner une classe
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="bg-light rounded h-100 p-4">
                        <select
                          id="classe"
                          name="classe_id"
                          value={selectedClassId}
                          onChange={handleClassChange}
                          className="form-select mb-3"
                        >
                          <option>Choisir une classe</option>
                          {renderClasses()}
                        </select>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-primary" data-bs-dismiss="modal">
                        OK
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-xl-6 mb-1">
              <Link to="send-notification" className="btn btn-success text-uppercase">
                Envoyer Une Notification
              </Link>
            </div>
          </div>

          {isLoading ? (
            <Spinner />
          ) : (
            <div className="row g-4">
              {/* Afficher les notifications par élève */}
              <div className="col-sm-12 col-md-6 col-xl-6">
                <div className="h-100 bg-light rounded p-4">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <h6 className="mb-0">
                      Notifications Envoyées : {selectedClassName ? ` ${selectedClassName}` : ""}
                    </h6>
                  </div>
                  {error ? (
                    <p className="text-danger">{error}</p>
                  ) : (
                    notifications.length > 0 ? (
                      notifications.map((notification) => (
                        <Link
                          to={`/dashboard/notification/student/details/${encryptParam(notification.student)}`}
                          className="d-flex align-items-center border-bottom py-3"
                          key={notification.id}
                        >
                          <img
                            className="rounded-circle flex-shrink-0"
                            src={notification.student_infos.image}
                            alt=""
                            style={{ width: "40px", height: "40px" }}
                          />
                          <div className="w-100 ms-3">
                            <div className="d-flex w-100 justify-content-between">
                              <h6 className="mb-0 text-capitalize">
                                {notification.student_infos.first_name} {notification.student_infos.last_name}
                              </h6>
                              <small className="text-capitalize fw-bold">
                                {moment.utc(notification.date).format('LL')}
                              </small>
                            </div>
                            <span>{notification.message.substring(0, 25)}...</span>
                          </div>
                        </Link>
                      ))
                    ) : (
                      <p>Aucune notification disponible pour la classe sélectionnée.</p>
                    )
                  )}
                </div>
              </div>
            
            </div>
          )}
        </div>
      </div>

      <BackToTop />
    </div>
  );
}

export default NotificationManagement;
