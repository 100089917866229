import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAxios from "../../utils/useAxios";

import Header from "../../components/Agent/Header";
import Sidebar from "../../components/Agent/Sidebar";
import BackToTop from "../../components/BackToTop";
import { encryptParam } from "../../utils/crypto";
import Spinner from "../../components/Spinner";

function TeacherManagement() {
  useEffect(() => {
    document.title = "Gestion Des Professeurs";
  }, []);

  const axiosInstance = useAxios();
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [latestSchoolYear, setLatestSchoolYear] = useState(null);

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      try {
        // Récupérer les années scolaires
        const schoolYearsResponse = await axiosInstance.get("/schoolyears/");
        const schoolYears = schoolYearsResponse.data;

        if (schoolYears.length > 0) {
          // Définir la dernière année scolaire
          const latestYear = schoolYears[schoolYears.length - 1];
          setLatestSchoolYear(latestYear);

          // Récupérer les professeurs pour la dernière année scolaire
          const teachersResponse = await axiosInstance.get(`/teachers-by-year/${latestYear.id}/`);
          setTeachers(teachersResponse.data);
        }
      } catch (error) {
        // console.error("Error fetching teachers:", error);
      }
      setLoading(false);
    };

    fetchInitialData();
  }, []);

  return (
    <div className="container-xxl position-relative bg-white d-flex p-0">
      <Sidebar />

      <div className="content">
        <Header />

        <div className="container-fluid pt-4 px-4">
          <div className="row my-2">
            <div className="col-sm-12 col-xl-6">
              <h3 className="text-uppercase">
                Gestion des professeurs
              </h3>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-sm-12 col-xl-6 mb-1">
              <Link to="add-teacher" className="btn btn-success text-uppercase">
                Ajouter Un Professeur 
              </Link>
            </div>
          </div>

          <div className="row g-4">
            <div className="col-sm-12 col-md-12 col-xl-12">
              <div className="h-100 bg-light rounded p-4">
                <div className="table-responsive">
                  {loading ? (
                    <Spinner />
                  ) : (
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">N°</th>
                          <th scope="col">Prenom</th>
                          <th scope="col">Nom</th>
                          <th scope="col">Classe</th>
                          <th scope="col">Téléphone</th>
                          <th scope="col">Année Scolaire</th>
                          <th scope="col">Modifier</th>
                        </tr>
                      </thead>
                      <tbody>
                        {teachers && teachers.length > 0 ? (
                          teachers.map((teacher, i) => (
                            <tr key={teacher.id}>
                              <td>{i + 1}</td>
                              <td>{teacher.first_name}</td>
                              <td>{teacher.last_name}</td>
                              <td>{teacher.classe_infos?.name}</td>
                              <td>{teacher.phone}</td>
                              <td>
                                {teacher.school_year.start_date} - {teacher.school_year.end_date}
                              </td>
                              <td>
                                <Link
                                  to={`/dashboard/teacher/edit-teacher/${encryptParam(teacher.id)}`}
                                  className="btn btn-sm btn-warning"
                                >
                                  Modifier
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7" className="text-center">Aucun professeur trouvé.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BackToTop />
    </div>
  );
}

export default TeacherManagement;
