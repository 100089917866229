import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useAxios from "../../utils/useAxios";
import moment from "moment";
import 'moment/locale/fr';

import Header from "../../components/Parent/Header";
import Sidebar from "../../components/Parent/Sidebar";
import BackToTop from "../../components/BackToTop";
import { decryptParam } from "../../utils/crypto";
import Spinner from "../../components/Spinner"; // Import the Spinner component
import config from "../../utils/config";

moment.locale('fr');  

function NotificationDetail() {
  useEffect(() => {
    document.title = "Details Notification";
  }, []);

  const [categoryNotifications, setCategoryNotifications] = useState([]);
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  const { id } = useParams();
  const axios = useAxios();

  useEffect(() => {
    const fetchNotification = async () => {
      try {
        const idDecrypt = decryptParam(id);
        const response = await axios.get("parents/notifications/" + idDecrypt + "/");
        setNotification(response.data);
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error(error);
        setLoading(false); // Set loading to false in case of error
      }
    };

    fetchNotification();
  }, [id]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get("/category-notifications/");
        setCategoryNotifications(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCategories();
  }, []);

  if (loading) {
    return <Spinner />; // Show spinner while loading
  }

  if (!notification) {
    return (
      <div className="container-xxl position-relative bg-white d-flex p-0">
        <Sidebar />
        <div className="content">
          <Header />
          <div className="container-fluid pt-4 px-4">
            <div className="p-3 bg-light text-center">
              <h4>Aucune notification disponible.</h4>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const getCheckboxColor = (id) => {
    if (id === 3 || id === 6 || id === 8 || id === 10) {
      return 'yellow';
    } else if ([1, 4, 7, 11].includes(id)) {
      return 'green';
    } else if ([2, 5, 9, 12].includes(id)) {
      return 'red';
    } else {
      return 'default';
    }
  };

  return (
    <div className="container-xxl position-relative bg-white d-flex p-0">
      <Sidebar />

      <div className="content">
        <Header />

        <div className="container-fluid pt-4 px-4">
          <div className="row g-4">
            <div className="col-sm-12 col-xl-12 p-2 bg-light d-flex align-items-center">
              <img
                className="rounded-circle"
                src={notification.notification.student_infos.image ? `${config.baseUrl}${notification.notification.student_infos.image}` : `${config.baseUrl}/media/default.jpg`}
                alt=""
                style={{ width: '100px', height: '100px' }}
              />
              <div className="ms-3">
                <h5 className="text-primary">{notification.notification.student_infos.first_name}</h5>
                <h5 className="text-primary">{notification.notification.student_infos.last_name}</h5>
                <h6>{notification.notification.student_infos.matricule}</h6>
                <h6>{notification.notification.student_infos.classe_infos.name}</h6>
                <h6>{notification.notification.student_infos.school_infos.name}</h6>
              </div>
            </div>
            <hr />

            <div className="col-sm-12 col-xl-12">
              <div className="bg-light rounded h-100 p-3">
                <div className="border-bottom p-1 bg-white text-dark">
                  <div className="p-2 bd-highlight fw-bold text-capitalize">
                    {moment.utc(notification.notification.date).format('LL')}
                  </div>
                  <div className="card p-2">
                    
                    <div className="d-flex flex-wrap my-2">
                      {categoryNotifications
                        .filter(category => notification.notification.categories && notification.notification.categories.includes(category.id))
                        .map((category, i) => (
                          <div key={i} className="d-flex align-items-center justify-content-between m-1">
                            <i 
                              className="fas fa-check-circle" 
                              style={{ 
                                color: getCheckboxColor(category.id)
                              }}>
                            </i>
                            <label className="text-capitalize" htmlFor={`category${category.id}`}>{category.name}</label>
                          </div>
                        ))
                      }
                    </div>
                    <div className="p-2">
                      <h6>{notification.notification.message}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BackToTop />
    </div>
  );
}

export default NotificationDetail;
