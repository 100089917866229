import useAxios from "../../utils/useAxios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Header from "../../components/Agent/Header";
import Sidebar from "../../components/Agent/Sidebar";
import BackToTop from "../../components/BackToTop";

import swal from "sweetalert2";
import { decryptParam } from "../../utils/crypto";

const EditTeacher = () => {
  const navigate = useNavigate();
  const axiosInstance = useAxios();
  const { id } = useParams();
  const [classes, setClasses] = useState([]);
  const [latestSchoolYear, setLatestSchoolYear] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    classe_id: "",
    school_year_id: ""
  });

  useEffect(() => {
    document.title = "Modifier Un Professeur";

    const fetchData = async () => {
      try {
        const schoolYearsResponse = await axiosInstance.get("/schoolyears/");
        const schoolYears = schoolYearsResponse.data;

        if (schoolYears.length > 0) {
          const latestYear = schoolYears[schoolYears.length - 1];
          setLatestSchoolYear(latestYear);
          setFormData((prevFormData) => ({
            ...prevFormData,
            school_year_id: latestYear.id
          }));

          const classesResponse = await axiosInstance.get(`/classe/?school_year_id=${latestYear.id}`);
          setClasses(classesResponse.data);
        }

        if (id) {
          const idDecrypt = decryptParam(id);
          const teacherResponse = await axiosInstance.get(`/teacher/${idDecrypt}/`);
          setFormData({
            first_name: teacherResponse.data.first_name,
            last_name: teacherResponse.data.last_name,
            phone: teacherResponse.data.phone,
            classe_id: teacherResponse.data.classe_infos.id,
            school_year_id: teacherResponse.data.school_year.id
          });
        }
      } catch (error) {
        // console.log(error);
      }
    };

    fetchData();
  }, [id]);

  const validateFormData = () => {
    const { first_name, last_name, phone, classe_id, school_year_id } = formData;
    if (!first_name || !last_name || !phone || !classe_id || !school_year_id) {
      swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: "Tous les champs sont obligatoires.",
      });
      return false;
    }

    const phoneRegex = /^\d{9}$/;
    if (!phoneRegex.test(phone)) {
      swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: "Le numéro de téléphone doit contenir exactement 9 chiffres. Exemple : 620112233",
      });
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!validateFormData()) {
      setIsLoading(false);
      return;
    }

    try {
      const formDataToSend = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        phone: formData.phone,
        classe_id: formData.classe_id,
        school_year_id: formData.school_year_id
      };

      const idDecrypt = decryptParam(id);

      const response = await axiosInstance.put(`/teacher/${idDecrypt}/`, formDataToSend);

      if (response.status === 200) {
        navigate("/dashboard/teacher");
        swal.fire({
          title: "Professeur modifié avec succès",
          icon: "success",
          toast: true,
          timer: 6000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: true,
        });
      } else {
        swal.fire({
          icon: "error",
          title: "Echec",
          text: "Une erreur s'est produite, veuillez réessayer",
        });
      }
    } catch (error) {
      swal.fire({
        icon: "error",
        title: "Echec",
        text: "Une erreur s'est produite, veuillez réessayer",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "school_year_id") {
      const fetchClasses = async () => {
        try {
          const response = await axiosInstance.get(`/classe/?school_year_id=${value}`);
          setClasses(response.data);
        } catch (error) {
          // console.log(error);
        }
      };

      fetchClasses();
    }
  };

  return (
    <div className="container-xxl position-relative bg-white d-flex p-0">
      <Sidebar />

      <div className="content">
        <Header />

        <div className="container-fluid pt-4 px-4">
          <div className="row g-4">
              <h3 className="mb-4 text-center">Modifier Un Professeur</h3>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="bg-light rounded h-100 p-4">
              <label className="form-label">Prenom</label>
              <input
                id="first_name"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                className="form-control mb-3"
                placeholder="Exemple : Mouctar Ibrahima"
              />
              <label className="form-label">Nom</label>
              <input
                id="last_name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                className="form-control mb-3"
                placeholder="Exemple : Camara"
              />
              <label className="form-label">Téléphone</label>
              <input
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="form-control mb-3"
                placeholder="Exemple : 620000000"
              />
              <label className="form-label">Classe</label>
              <select
                id="classe_id"
                name="classe_id"
                value={formData.classe_id}
                onChange={handleChange}
                className="form-select mb-3"
              >
                <option value="" disabled>
                  Choisir une classe
                </option>
                {classes.map((classe) => (
                  <option key={classe.id} value={classe.id}>
                    {classe.name}
                  </option>
                ))}
              </select>
              <label className="form-label">Année Scolaire</label>
              <select
                id="school_year_id"
                name="school_year_id"
                value={formData.school_year_id}
                onChange={handleChange}
                className="form-select mb-3"
                disabled
              >
                {latestSchoolYear && (
                  <option value={latestSchoolYear.id}>
                    {latestSchoolYear.start_date} - {latestSchoolYear.end_date}
                  </option>
                )}
              </select>
              
              <button
                type="submit"
                className="btn btn-primary m-2"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">Chargement...</span>
                  </div>
                ) : (
                  "Modifier"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>

      <BackToTop />
    </div>
  );
};

export default EditTeacher;
